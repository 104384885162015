import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { IoArrowBack } from "react-icons/io5";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { TiTick } from "react-icons/ti";
import ReactGA from "react-ga";
import gears from "../../../multimedia/loadingBigStarter.gif";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function DubbedVideo({
  projectId,
  setSelectedTranscendComponent,
  languageId,
  setLanguageId,
  setVoicesArray,
  setProjectId,
}) {
  const [data, setData] = useState("");
  const [flag, setFlag] = useState(false);
  const apiURL = process.env.REACT_APP_API_URL;
  const [showModal, setShowModal] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    ReactGA.event({
      category: "Modal",
      action: "Open",
      label: "dubbed_results/lip_sync",
    });
  };

  const openModal = (errorMessage) => {
    setModalErrorMessage(errorMessage);
    setShowModal(true);
  };

  const closeModal = () => setShowModal(false);

  const notify = (error) =>
    toast.error(error, {
      className: "custom-error-toast",
    });

  // google analytics

  const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview("/spectral-transcend/dubbed_results");
    ReactGA.set({ page: "/spectral-transcend/dubbed_results" });
  }, []);

  const fetchVoice = async (project_Id) => {
    const reqBody = {
      UserId: localStorage.getItem("userSessionId"),
      ProjectId: project_Id,
    };
    try {
      const response = await fetch(`${apiURL}/web/get_voices`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.IsValid) {
          localStorage.setItem("voiceList", JSON.stringify(data.VoiceList));
          setVoicesArray(data.VoiceList);
        }
      } else {
        throw new Error("Failed to fetch project data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  async function change_project_result_status(currentStatus, data) {
    const req = {
      ProjectId: projectId,
      CurrentStatus: currentStatus,
      TargetStatus: 10,
    };
    try {
      const response = await fetch(
        `${apiURL}/web/change_project_result_status`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // 'ApiKey': apiKey,
          },
          body: JSON.stringify(req),
        }
      );
      const res = await response.json();

      if (res.IsValid) {
        setProjectId(data.Id);
        setLanguageId(data.ResultVideos[0].TargetLangId);
        fetchVoice(data.Id);
        setSelectedTranscendComponent("audio_segments");
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getProject(id) {
    const req = {
      ProjectId: id,
    };
    try {
      const response = await fetch(`${apiURL}/web/get_project`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'ApiKey': apiKey,
        },
        body: JSON.stringify(req),
      });
      const data = await response.json();

      if (data.IsValid) {
        change_project_result_status(data.ResultVideos[0].Status, data);
      } else {
        notify("Redirection to previous stage was unsuccessful");
      }
    } catch (error) {
      console.error(error);
      notify("Redirection to previous stage was unsuccessful");
    }
  }

  function back() {
    getProject(projectId);
  }

  useEffect(() => {
    const fetchVideo = async () => {
      const req = {
        ProjectId: projectId,
        TargetLangId: languageId,
      };
      try {
        const response = await fetch(`${apiURL}/dub/check_dubbing_status`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(req),
        });

        if (response.ok) {
          const newData = await response.json();
          if (newData.IsValid) {
            if (newData.DubbingStatus === 2) {
              setData(newData.DubbedResultUrls[0]);
              clearInterval(intervalId);
              setFlag(true);
            }
          }
        } else {
          throw new Error("Failed to fetch project data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchVideo();
    const intervalId = setInterval(fetchVideo, 5000);
    return () => clearInterval(intervalId);
  }, []);

  async function handleSync() {
    const req = {
      ProjectId: projectId,
      TargetLangId: languageId,
    };
    try {
      const response = await fetch(`${apiURL}/dub/spectral_sync`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
      });

      if (response.ok) {
        const res = await response.json();

        if (res.IsValid) {
          handleClose();
          setSelectedTranscendComponent("spectral-sync");
        } else {
          if (res.Error === true) {
            handleClose();
            openModal(res.ErrorResponse);
          }
        }
      } else {
        throw new Error("Failed to speactral sync");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  return (
    // <div className='syncPage' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '70vh' }}>
    <div style={{ padding: "1%" }}>
      <Row>
        <Col md={12}>
          <div className="transcendPage2" style={{ position: "relative" }}>
            {/* <div className='pg4 result-page' > */}
            {flag === true ? (
              <div style={{ position: "absolute", left: 10, top: 10 }}>
                <Button
                  style={{ fontSize: "20px", fontWeight: 500 }}
                  variant="text"
                  onClick={(e) => back()}
                >
                  <IoArrowBack />
                  <span className="ms-1" style={{ fontSize: "14px" }}>
                    Back
                  </span>
                </Button>
              </div>
            ) : null}
            <h5 className=" mb-4 text-center">Download Dubbed Video</h5>
            <div className="result-video-container">
              {data !== "" ? (
                <video className="finalVideo" src={data} controls></video>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "40vw",
                    }}
                  >
                    <img
                      style={{ height: "35%", width: "40%" }}
                      src={gears}
                      alt="In progress"
                    />

                    <p style={{ fontWeight: 500, marginTop: "20px" }}>
                      Please wait, localization is in progress.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
      <div className="nxtBtnContainer">
        {flag === true ? (
          <div>
            {" "}
            <a href={data}>
              <button className="nxtBtn ">Export</button>
            </a>{" "}
            <button className="nxtBtn ms-2" onClick={(e) => handleShow()}>
              Spectral Sync
            </button>{" "}
          </div>
        ) : (
          <button className="nxtBtn" disabled>
            Export
          </button>
        )}
      </div>
      <Modal show={showModal} onHide={closeModal} size="md">
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: "red" }}>{modalErrorMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <h5>Before start, make sure your video meets these criteria:</h5>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>
              <span className="me-1">
                <TiTick />
              </span>
              <span>The face is clearly visible</span>
            </p>
            <p>
              <span className="me-1">
                <TiTick />
              </span>
              <span>Nothing covers the eyes, nose, mouth and eyebrows</span>
            </p>
            <p>
              <span className="me-1">
                <TiTick />
              </span>
              <span>The light is good, no shadows or over exposure</span>
            </p>
          </div>
          <div style={{ width: "100%" }}>
            <Button
              style={{ width: "100%" }}
              className="mt-3"
              variant="primary"
              onClick={(e) => handleSync()}
            >
              Start Lip-sync
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </div>
  );
}
