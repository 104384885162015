import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import loading from "../../../multimedia/loading.gif";
import Spinner from "react-bootstrap/Spinner";
import ReactGA from "react-ga";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { HiInformationCircle } from "react-icons/hi2";
import { FaUpload } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import gears from "../../../multimedia/loadingBigStarter.gif";
import { IoArrowBack } from "react-icons/io5";
import { IoWarningOutline } from "react-icons/io5";
import Popover from "react-bootstrap/Popover";

export default function TranscendTranslate({
  setSelectedTranscendComponent,
  projectName,
  languageId,
  projectId,
  setProjectId,
  setProjectName,
  setLanguageId,
  setVideoUrl,
  videoUrl,
}) {
  const apiURL = process.env.REACT_APP_API_URL;
  const [data, setData] = useState([]);
  const [flag, setFlag] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [temp, setTemp] = useState([]);
  const [reWrittenTranslation, setReWrittenTranslation] = useState(null);
  const [reWriteMessage, setReWriteMessage] = useState({});
  const [updating, setUpdating] = useState(false);
  const [updatingIndex, setUpdatingIndex] = useState(null);
  const [saved, setSaved] = useState(false);
  const [savedIndex, setSavedIndex] = useState(null);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [showOverlay, setShowOverlay] = useState({});
  const [showImport, setShowImport] = useState(false);
  const handleImportClose = () => {
    setShowImport(false);
    handleDeleteFile();
  };
  const handleImportShow = () => {
    ReactGA.event({
      category: "Modal",
      action: "Open",
      label: "translate/import_translations",
    });
    setShowImport(true);
  };

  const notify = (error) =>
    toast.error(error, {
      className: "custom-error-toast",
    });

  // google analytics

  const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview("/spectral-transcend/translate");
    ReactGA.set({ page: "/spectral-transcend/translate" });
  }, []);

  function handleFileChange(selectedFile) {
    console.log(selectedFile.name);
    setFile(selectedFile);
  }

  function handleDrop(e) {
    e.preventDefault();
    const selectedFile = e.dataTransfer.files[0];
    console.log(selectedFile.name);
    setFile(selectedFile);
  }

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleDeleteFile = () => {
    setFile(null);
  };

  async function change_project_result_status() {
    const req = {
      ProjectId: projectId,
      CurrentStatus: currentStatus,
      TargetStatus: 6,
    };
    try {
      const response = await fetch(
        `${apiURL}/web/change_project_result_status`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // 'ApiKey': apiKey,
          },
          body: JSON.stringify(req),
        }
      );
      const res = await response.json();

      if (res.IsValid) {
        setProjectId(projectId);
        setProjectName(projectName);
        setLanguageId(languageId);
        setVideoUrl(videoUrl);
        setSelectedTranscendComponent("transcribe");
      } else {
        notify("Redirection to previous stage was unsuccessful");
      }
    } catch (error) {
      console.error(error);
      notify("Redirection to previous stage was unsuccessful");
    }
  }

  function back() {
    // console.log('back clicked');
    if (currentStatus !== null) {
      change_project_result_status();
    }
  }
  const InformationCircleTooltip = () => (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip style={{ fontSize: "12px" }}>
          Click here to download the reference file format
        </Tooltip>
      }
    >
      <Button
        variant="text"
        style={{ color: "gray" }}
        onClick={(e) => handleInfoClick()}
      >
        <HiInformationCircle />
      </Button>
    </OverlayTrigger>
  );

  function handleInfoClick() {
    // Make the Fetch POST call
    fetch(`${apiURL}/web/get_reference_translation_file`, {
      method: "POST",
      // No request body for a POST call according to your description
    })
      .then((response) => {
        // Check if the response is successful
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob(); // Extract the blob from the response
      })
      .then((blob) => {
        // Create a URL for the blob object
        const url = window.URL.createObjectURL(new Blob([blob]));
        // Create a link element
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "reference_translation.xlsx"); // Set the filename
        // Append the link to the body
        document.body.appendChild(link);
        // Trigger the download
        link.click();
        // Clean up
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }

  // function call to update the exporting translations
  const updateExportTranslations = async () => {
    const temp = data.map((item) => ({
      TranscriptionId: item.TranscriptionId,
      TranslationId: item.TranslationId,
      TranslationText: item.TranslationText,
      TargetLangId: languageId,
    }));
    const reqBody = {
      ProjectId: projectId,
      TranslationList: temp,
    };
    try {
      const response = await fetch(`${apiURL}/web/update_translations`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const res = await response.json();

        if (res.IsValid) {
          exportTranslations();
        }
      } else {
        throw new Error("Failed to fetch project data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  // function call to export translations
  function exportTranslations() {
    const reqBody = {
      ProjectId: projectId,
      Type: 2,
    };
    fetch(`${apiURL}/web/export_translations`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Add any other headers if needed
      },
      body: JSON.stringify(reqBody),
    })
      .then((response) => {
        // Check if the response is successful
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        ReactGA.event({
          category: "Download",
          action: "Download",
          label: "translate/download_translations",
        });
        return response.blob(); // Extract the blob from the response
      })
      .then((blob) => {
        // Create a URL for the blob object
        const url = window.URL.createObjectURL(new Blob([blob]));
        // Create a link element
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${projectName}_translations.xlsx`); // Set the filename
        // Append the link to the body
        document.body.appendChild(link);
        // Trigger the download
        link.click();
        // Clean up
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }

  const formData = new FormData();
  formData.append("ProjectId", projectId);
  formData.append("file", file);

  function handleFileImport() {
    setFlag(false);
    fetch(`${apiURL}/web/import_translations`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to fetch project data");
        }
      })
      .then((res) => {
        if (res.IsValid) {
          createTranslations();
          handleImportClose();
        } else {
          console.error("Failed to upload translation file");
          notify("Import unsuccessful, please upload a valid file!");
        }
      })
      .catch((error) => {
        console.error("Error while uploading translation file:", error);
      });
  }

  const createTranslations = async () => {
    const reqBody = {
      ProjectId: projectId,
      TargetLanguageId: languageId,
    };

    setNextClicked(true);
    try {
      const response = await fetch(`${apiURL}/dub/translate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const res = await response.json();

        if (res.IsValid) {
          setData([]);
          handleGetTranslations();
        }
      } else {
        setNextClicked(false);
        throw new Error("Failed to fetch project data");
      }
    } catch (error) {
      setNextClicked(false);
      // console.error('Error:', error);
    }
  };

  function handleGetTranslations() {
    function fetchTranslations() {
      const reqBody = {
        ProjectId: projectId,
        TargetLanguageId: languageId,
      };

      fetch(`${apiURL}/web/get_translations`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Failed to fetch project data");
          }
        })
        .then((newData) => {
          if (newData.IsValid) {
            if (newData.Status === 8) {
              setCurrentStatus(newData.Status);
              clearInterval(intervalId);
              setFlag(true);
            }
            setData([...(newData.ResultTranslations || [])]);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }

    fetchTranslations();
    const intervalId = setInterval(fetchTranslations, 5000);

    return () => clearInterval(intervalId);
  }

  useEffect(() => {
    function fetchTranslations() {
      const reqBody = {
        ProjectId: projectId,
        TargetLanguageId: languageId,
      };

      fetch(`${apiURL}/web/get_translations`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Failed to fetch project data");
          }
        })
        .then((newData) => {
          if (newData.IsValid) {
            if (newData.Status === 8) {
              clearInterval(intervalId);
              setCurrentStatus(newData.Status);
              setFlag(true);
            }
            setData([...(newData.ResultTranslations || [])]);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }

    fetchTranslations();
    const intervalId = setInterval(fetchTranslations, 5000);

    return () => clearInterval(intervalId);
  }, []);

  // api call for updating transcriptions

  const updateTranslations = async () => {
    const temp = data.map((item) => ({
      TranscriptionId: item.TranscriptionId,
      TranslationId: item.TranslationId,
      TranslationText: item.TranslationText,
      TargetLangId: languageId,
    }));
    const reqBody = {
      ProjectId: projectId,
      TranslationList: temp,
    };
    setNextClicked(true);
    try {
      const response = await fetch(`${apiURL}/web/update_translations`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const res = await response.json();

        if (res.IsValid) {
          generateAudioSegments();
        }
      } else {
        setNextClicked(false);
        throw new Error("Failed to fetch project data");
      }
    } catch (error) {
      console.error("Error:", error);
      setNextClicked(false);
    }
  };

  const resetSaved = () => {
    setTimeout(() => {
      setSaved(false);
      setSavedIndex(null);
    }, 2000);
  };
  // function call to update single translation

  async function updateSingleTranslation(translation, index) {
    setUpdating(true);
    setUpdatingIndex(index);
    const reqBody = {
      ProjectId: projectId,
      TranslationList: translation,
    };
    try {
      const response = await fetch(`${apiURL}/web/update_translations`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const res = await response.json();

        if (res.IsValid) {
          setUpdating(false);
          setUpdatingIndex(null);
          setSaved(true);
          setSavedIndex(index);
          resetSaved();
          handleGetTranslations();
        }
      } else {
        setNextClicked(false);
        setUpdating(false);
        throw new Error("Failed to fetch project data");
      }
    } catch (error) {
      console.error("Error:", error);
      setNextClicked(false);
      setUpdating(false);
    }
  }

  // handleNext function that calls generate audio segments api

  const generateAudioSegments = async () => {
    const request = {
      ProjectId: projectId,
      TargetLanguageId: languageId,
    };
    // console.log("generate audio segments req body",request);
    try {
      const response = await fetch(`${apiURL}/dub/generate_audio_segments`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      });

      if (response.ok) {
        const res = await response.json();

        if (res.IsValid) {
          setSelectedTranscendComponent("audio_segments");
        }
      } else {
        setNextClicked(false);
        throw new Error("Failed to fetch project data");
      }
    } catch (error) {
      setNextClicked(false);
      console.error("Error:", error);
    }
  };

  function handleNext() {
    if (flag === true) {
      updateTranslations();
    }
  }

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    const milliseconds = Math.round((timeInSeconds % 1) * 100);

    const pad = (value, length) => {
      const strValue = value.toString();
      return strValue.length < length ? pad(`0${strValue}`, length) : strValue;
    };

    return `${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}.${pad(
      milliseconds,
      2
    )}`;
  };

  const handleTextareaChange = (index, value, subs) => {
    const initialText = data[index].TranslationText;
    if (flag === false) {
      const temporary = [...data];
      temporary[index].TranslationText = value;
      setTemp(temporary);
    }
    const newText = value;
    setData((prevData) =>
      prevData.map((item, i) =>
        i === index ? { ...item, TranslationText: newText } : item
      )
    );
    const translation = [subs];
    const temp = translation.map((item) => ({
      TranscriptionId: item.TranscriptionId,
      TranslationId: item.TranslationId,
      TranslationText: newText,
      TargetLangId: languageId,
    }));
    // console.log("initial",initialText);
    // console.log("updated text ",newText)
    if (initialText !== newText) {
      updateSingleTranslation(temp, index);
    }
  };

  useEffect(() => {
    // Once the condition is met, update the main state with changes from the temporary state
    if (flag && temp.length > 0) {
      setData((prevData) =>
        prevData.map((item, index) =>
          temp[index] ? { ...item, ...temp[index] } : item
        )
      );
    }
  }, [flag, temp]);

  async function reWriteTranslation(id, text, index) {
    const reqBody = {
      // "ProjectId": projectId,
      TranslationList: [
        {
          TranslationId: id,
          TranslationText: text,
        },
      ],
    };
    try {
      const response = await fetch(`${apiURL}/web/rewrite_translations `, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const res = await response.json();

        if (res.IsValid) {
          if (
            res.RewrittenTranslationList[0].TranslationId ===
            data[index].TranslationId
          ) {
            setReWrittenTranslation(res.RewrittenTranslationList[0]);
            setReWriteMessage((prevState) => ({
              ...prevState,
              [index]: false,
            }));
          }
        }
      } else {
        setReWriteMessage((prevState) => ({ ...prevState, [index]: false }));
        throw new Error("Failed to fetch project data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  function reWrite(translation, index) {
    // console.log('required translation is', translation.TranslationId, translation.TranslationText);
    if (data[index].TranslationId === translation.TranslationId) {
      setReWriteMessage((prevState) => ({ ...prevState, [index]: true }));
      // console.log("match");
      setTimeout(() => {
        reWriteTranslation(
          translation.TranslationId,
          translation.TranslationText,
          index
        );
      }, 2000);
    }
  }

  function applyReWrite(index) {
    const tempTranslation = [data[index]].map((item) => ({
      TranscriptionId: item.TranscriptionId,
      TranslationId: item.TranslationId,
      TranslationText: reWrittenTranslation.RewrittenTranslationText,
      TargetLangId: languageId,
    }));

    // console.log(data[index].TranslationId, "is data id and reWritten id is ", reWrittenTranslation.TranslationId);

    updateSingleTranslation(tempTranslation, index);
    setReWrittenTranslation(null);
    setShowOverlay((prevState) => ({ ...prevState, [index]: false }));
  }

  return (
    // <div className='syncPage'>
    <div style={{ padding: "1%" }}>
      <div className="pg4" style={{ position: "relative" }}>
        <span>
          {flag === true ? (
            <span style={{ position: "absolute", left: 10, top: 10 }}>
              <Button
                style={{ fontSize: "20px", fontWeight: 500 }}
                variant="text"
                onClick={(e) => back()}
              >
                <IoArrowBack />
                <span className="ms-1" style={{ fontSize: "14px" }}>
                  Back
                </span>
              </Button>
            </span>
          ) : null}
        </span>
        <span>
          {" "}
          <h5 style={{ textAlign: "center" }}>Edit Translation</h5>
          <div
            className="mb-3"
            style={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}
          >
            {flag === true ? (
              <Button
                variant="outline-dark"
                onClick={(e) => handleImportShow()}
              >
                Import
              </Button>
            ) : (
              <Button variant="outline-dark" disabled>
                Import
              </Button>
            )}
            {flag === true ? (
              <Button
                variant="outline-dark"
                onClick={(e) => updateExportTranslations()}
              >
                Export
              </Button>
            ) : (
              <Button variant="outline-dark" disabled>
                Export
              </Button>
            )}
            <InformationCircleTooltip />
          </div>
          {flag === false && nextClicked === false && data.length > 0 ? (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <span
                style={{
                  marginRight: "10px",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                Translation is in progress
              </span>{" "}
              <Spinner size="sm" animation="border" variant="success" />
            </div>
          ) : null}
        </span>
        <Row>
          <div className="captionTranslate">
            {data.length > 0 ? (
              data.map((subs, index) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                  key={subs.TranslationId}
                  onMouseLeave={(e) =>
                    setShowOverlay((prevState) => ({
                      ...prevState,
                      [index]: false,
                    }))
                  }
                >
                  <div className="translations">
                    <div className="speaker_select" style={{ width: "10%" }}>
                      <p style={{ fontWeight: 500 }}>Speaker</p>

                      <Form.Select
                        value={subs.SpeakerName}
                        style={{
                          height: "40px",
                          fontSize: "14px",
                          width: "auto",
                          overflow: "hidden",
                        }}
                        readOnly
                      >
                        {<option key={index}>{subs.SpeakerName}</option>}
                        onChange=
                        {(e) => {
                          const newSpeakerName = e.target.value;
                          setData((prevData) =>
                            prevData.map((item, i) =>
                              i === index
                                ? { ...item, SpeakerName: newSpeakerName }
                                : item
                            )
                          );
                        }}
                      </Form.Select>
                    </div>

                    <div className="timers" style={{ width: "10%" }}>
                      <Form.Control
                        type="text"
                        style={{
                          textAlign: "center",
                          width: "auto",
                          fontSize: "14px",
                          height: "40px",
                        }}
                        value={formatTime(subs.StartTime)}
                        readOnly
                      />
                      <Form.Control
                        type="text"
                        style={{
                          textAlign: "center",
                          width: "auto",
                          fontSize: "14px",
                          height: "40px",
                        }}
                        value={formatTime(subs.EndTime)}
                        readOnly
                      />
                    </div>

                    <div
                      className="textareaDiv"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexGrow: 1,
                        width: "35%",
                      }}
                    >
                      <Form.Control
                        as="textarea"
                        className="transcript-div"
                        rows={3}
                        cols={52}
                        style={{ resize: "none" }}
                        value={subs.TranscriptionText}
                        readOnly
                        data-gramm="false"
                        data-gramm_editor="false"
                        data-enable-grammarly="false"
                      />
                    </div>
                    <div className="timers" style={{ width: "10%" }}>
                      <Form.Control
                        type="text"
                        style={{
                          textAlign: "center",
                          width: "auto",
                          fontSize: "14px",
                          height: "40px",
                        }}
                        value={formatTime(subs.StartTime)}
                        readOnly
                      />
                      <Form.Control
                        type="text"
                        style={{
                          textAlign: "center",
                          width: "auto",
                          fontSize: "14px",
                          height: "40px",
                        }}
                        value={formatTime(subs.EndTime)}
                        readOnly
                      />
                    </div>

                    {subs.TranslationReady === true ? (
                      <div
                        className="textareaDiv"
                        id="translation-div"
                        style={{
                          width: "35%",
                          display: "flex",
                          flexDirection: "row",
                          flexGrow: 1,
                          position: "relative",
                        }}
                      >
                        <Form.Control
                          as="textarea"
                          className="translation-text-area"
                          rows={3}
                          cols={52}
                          style={{ resize: "none" }}
                          defaultValue={subs.TranslationText}
                          onBlur={(e) =>
                            handleTextareaChange(index, e.target.value, subs)
                          }
                          data-gramm="false"
                          data-gramm_editor="false"
                          data-enable-grammarly="false"
                          key={subs.TranslationId}
                        />
                        {updating === true && index === updatingIndex ? (
                          <div
                            style={{
                              position: "absolute",
                              bottom: "5px",
                              right: "5px",
                              color: "gray",
                              fontSize: "12px",
                            }}
                          >
                            <span>Saving</span>
                            <span className="ms-1 me-1">
                              <Spinner
                                variant="secondary"
                                animation="border"
                                size="sm"
                                style={{ height: "12px", width: "12px" }}
                              />
                            </span>
                          </div>
                        ) : null}
                        {saved === true && index === savedIndex ? (
                          <div
                            style={{
                              position: "absolute",
                              bottom: "5px",
                              right: "5px",
                              color: "gray",
                              fontSize: "12px",
                            }}
                          >
                            <span className="me-2">Saved</span>
                          </div>
                        ) : null}

                        {subs.IsWarning && (
                          <div
                            style={{
                              position: "absolute",
                              top: "5px",
                              right: 0,
                              color: "red",
                              fontSize: "16px",
                              cursor: "pointer",
                            }}
                          >
                            <OverlayTrigger
                              trigger="click"
                              key={subs.TranslationId}
                              show={showOverlay[index]}
                              placement={"left"}
                              overlay={
                                <Popover
                                  id={"popover-positioned-left"}
                                  style={{
                                    height: "auto",
                                    minHeight: "150px",
                                    width: "400px",
                                  }}
                                >
                                  <Popover.Header as="h3">
                                    {reWrittenTranslation !== null &&
                                    subs.TranslationId ===
                                      reWrittenTranslation.TranslationId &&
                                    reWriteMessage[index] === false
                                      ? "Rewrite"
                                      : "Fast Speech"}
                                  </Popover.Header>
                                  <Popover.Body>
                                    {reWrittenTranslation !== null &&
                                    subs.TranslationId ===
                                      reWrittenTranslation.TranslationId &&
                                    reWriteMessage[index] === false ? (
                                      reWrittenTranslation.RewrittenTranslationText
                                    ) : reWriteMessage[index] === true ? (
                                      <span
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          width: "100%",
                                          height: "100%",
                                          fontWeight: 600,
                                        }}
                                      >
                                        rewriting...
                                      </span>
                                    ) : (
                                      subs.WarningText
                                    )}
                                    {reWrittenTranslation !== null &&
                                    subs.TranslationId ===
                                      reWrittenTranslation.TranslationId &&
                                    reWriteMessage[index] === false ? (
                                      <div
                                        className="mt-4"
                                        style={{
                                          display: "flex",
                                          gap: "10px",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Button
                                          variant="secondary"
                                          onClick={(e) => reWrite(subs, index)}
                                        >
                                          ReWrite
                                        </Button>
                                        <Button
                                          onClick={(e) => applyReWrite(index)}
                                        >
                                          Apply
                                        </Button>
                                      </div>
                                    ) : reWriteMessage[index] === false ||
                                      reWriteMessage[index] === undefined ? (
                                      <Button
                                        variant="secondary"
                                        className="mt-4"
                                        style={{ width: "100%" }}
                                        onClick={(e) => reWrite(subs, index)}
                                      >
                                        Rewrite with AI
                                      </Button>
                                    ) : null}
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <span
                                className="me-2"
                                onMouseOver={(e) =>
                                  setShowOverlay((prevState) => ({
                                    ...prevState,
                                    [index]: true,
                                  }))
                                }
                              >
                                <IoWarningOutline />
                              </span>
                            </OverlayTrigger>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div
                        className="translate-message"
                        style={{ width: "35%" }}
                      >
                        {" "}
                        <p>
                          Translating{" "}
                          <span style={{ animation: "blink 1.5s infinite" }}>
                            ...
                          </span>
                        </p>
                        <style>
                          {`
    @keyframes blink {
      0%, 50%, 100% {
        opacity: 0;
      }
      25%, 75% {
        opacity: 1;
      }
    }
  `}
                        </style>
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : flag === false ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "40vw",
                  }}
                >
                  <img
                    style={{ height: "250px", width: "250px" }}
                    src={gears}
                    alt="In progress"
                  />
                  <p style={{ fontWeight: 500, marginTop: "20px" }}>
                    Please wait, translation is in progress.
                  </p>
                </div>
              </div>
            ) : (
              <p style={{ color: "red", textAlign: "center" }}>
                No translations for the project
              </p>
            )}
          </div>
        </Row>
      </div>
      <div className="nxtBtnContainer mb-3">
        {flag === true ? (
          <button
            className="nxtBtn"
            onClick={(e) => {
              handleNext();
            }}
          >
            Next
          </button>
        ) : flag === true && nextClicked === true ? (
          <div>
            {" "}
            <button className="nxtBtn" disabled>
              Next
            </button>{" "}
            <span>
              <img
                style={{ width: "50px", height: "50px", marginLeft: "10px" }}
                src={loading}
                alt="loading"
              />
            </span>{" "}
          </div>
        ) : (
          <button className="nxtBtn" disabled>
            Next
          </button>
        )}
      </div>
      <Modal show={showImport} onHide={handleImportClose}>
        <Modal.Header closeButton>
          <Modal.Title>Import translations</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ textAlign: "center", position: "relative" }}
          onDrop={(e) => handleDrop(e)}
          onDragOver={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {file !== null ? (
            <Button
              variant="text"
              className="delete-button"
              style={{
                position: "absolute",
                top: 15,
                right: 15,
                fontWeight: 700,
              }}
              onClick={handleDeleteFile}
            >
              <RiDeleteBin6Line />
            </Button>
          ) : null}
          {file === null ? (
            <div>
              <Button
                variant="text"
                style={{ fontSize: "50px" }}
                onClick={handleUploadClick}
              >
                <FaUpload />
              </Button>
              <p style={{ fontWeight: 500 }}>
                Drop your excel file here, or click to browse
              </p>
              <input
                ref={fileInputRef}
                type="file"
                style={{ display: "none" }}
                accept=".xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={(e) => handleFileChange(e.target.files[0])}
              />
            </div>
          ) : (
            <div>
              <p className="mb-2 mt-2">{file.name}</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleImportClose}>
            Cancel
          </Button>
          {file !== null ? (
            <Button variant="primary" onClick={(e) => handleFileImport()}>
              Upload
            </Button>
          ) : (
            <Button variant="secondary" disabled>
              Upload
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </div>
  );
}
