import React, { useEffect, useState } from "react";
import PersonalizePage1 from "./PersonalizePage1";
import PersonalizePage2 from "./PersonalizePage2";
import PersonalizePage3 from "./PersonalizePage3";
import PersonalizeHome from "./PersonalizeHome";

export default function SpectralPersonalize() {
  const [selectedPersonalizeComponent, setSelectedPersonalizeComponent] =
    useState("pHome");

  useEffect(() => {
    document.title = "Personalize - Spectral Studio";
  }, []);

  const renderSelectedPersonalizeComponent = () => {
    switch (selectedPersonalizeComponent) {
      case "pHome":
        return (
          <PersonalizeHome
            setSelectedPersonalizeComponent={setSelectedPersonalizeComponent}
          />
        );
      case "personalize1":
        return (
          <PersonalizePage1
            setSelectedPersonalizeComponent={setSelectedPersonalizeComponent}
          />
        );
      case "personalize2":
        return (
          <PersonalizePage2
            setSelectedPersonalizeComponent={setSelectedPersonalizeComponent}
          />
        );
      case "personalize3":
        return (
          <PersonalizePage3
            setSelectedPersonalizeComponent={setSelectedPersonalizeComponent}
          />
        );
      default:
        return null;
    }
  };
  return (
    <div className="mt-3 spectralPersonalize">
      <h2 className="mt-2 ms-5">Spectral Personalize</h2>
      <div>{renderSelectedPersonalizeComponent()}</div>
    </div>
  );
}
