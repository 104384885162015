import React, { useEffect, useState } from 'react';
import { IoCut } from 'react-icons/io5';
import { FiMoreVertical } from 'react-icons/fi';
import { Button, Card } from 'react-bootstrap';
import thumbnail from '../../../multimedia/thumbnail1200.png';
import ReactTimeAgo from 'react-time-ago';
import { MdOutlineEdit } from 'react-icons/md';
import { GoDownload } from 'react-icons/go';
import { RiDeleteBin6Line } from 'react-icons/ri';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import ReactGA from 'react-ga';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function TranscendHome({
  searchText,
  setSelectedTranscendComponent,
  setProjectId,
  setLanguageId,
  setVideoUrl,
  setProjectName,
  setVoicesArray,
  setVoice1,
  menuLock,
  setMenuLock,
}) {
  const apiURL = process.env.REACT_APP_API_URL;
  const [myTranscendProjects, setMyTranscendProjects] = useState([]);
  const [displayedCards, setDisplayedCards] = useState(9); // Number of cards to display initially
  const cardsPerPage = 9;
  const projectsApiUrl = `${apiURL}/web/get_project_list`;
  const [reName, setReName] = useState('');
  const [pid, setPid] = useState('');
  const [openMenuId, setOpenMenuId] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState('');
  const [disableDownload, setDisableDownload] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const notify = (error) =>
    toast.error(error, {
      className: 'custom-error-toast',
    });

  const openModal = (project_id) => {
    setPid(project_id);
    setShowModal(true);
    ReactGA.event({
      category: 'Modal',
      action: 'Open',
      label: 'projects/delete_project',
    });
  };

  useEffect(() => {
    setMenuLock(false);
  }, []);

  const closeModal = () => setShowModal(false);

  const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview('/spectral-transcend/projects');
  }, []);

  async function projectReName(project_id, SourceLangId, videoSrc) {
    const req = {
      ProjectId: project_id,
      Name: reName,
      SourceLangId: SourceLangId,
      VideoUrl: videoSrc,
    };

    try {
      const response = await fetch(`${apiURL}/web/update_project`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // 'ApiKey': apiKey,
        },
        body: JSON.stringify(req),
      });
      const data = await response.json();

      if (data.IsValid) {
        setReName('');

        fetchAllProjects();
        handleClose();
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function handleProjectReName() {
    const req = {
      ProjectId: pid,
    };

    try {
      const response = await fetch(`${apiURL}/web/get_project`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // 'ApiKey': apiKey,
        },
        body: JSON.stringify(req),
      });
      const data = await response.json();

      if (data.IsValid) {
        projectReName(data.Id, data.SourceLangId, data.VideoUrl);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const toggleOptions = (id) => {
    getProjectForDownload(id);
    setOpenMenuId(openMenuId === id ? null : id);
  };

  const handleShowMore = () => {
    setDisplayedCards((prev) => prev + cardsPerPage);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (project_id) => {
    setPid(project_id);
    setShow(true);
    ReactGA.event({
      category: 'Modal',
      action: 'Open',
      label: 'projects/rename_project',
    });
  };
  async function getProjectForDownload(project_id) {
    const req = {
      ProjectId: project_id,
    };
    try {
      const response = await fetch(`${apiURL}/web/get_project`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // 'ApiKey': apiKey,
        },
        body: JSON.stringify(req),
      });
      const data = await response.json();

      if (data.IsValid) {
        setReName(data.Name);
        if (
          data.ResultVideos[0].Status === 11 ||
          data.ResultVideos[0].Status === 12 ||
          data.ResultVideos[0].Status === 13
        ) {
          if (data.ResultVideos[0].DubbedVideoUrl) {
            setDisableDownload(false);
            setDownloadUrl(data.ResultVideos[0].DubbedVideoUrl);
          } else if (data.ResultVideos[0].Status === 14) {
            setDownloadUrl(data.ResultVideos[0].SpectralSyncedVideoUr);
          }
        } else {
          setDisableDownload(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function handleDeleteProject() {
    const req = {
      ProjectId: pid,
    };

    try {
      const response = await fetch(`${apiURL}/web/delete_project`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // 'ApiKey': apiKey,
        },
        body: JSON.stringify(req),
      });
      const data = await response.json();

      if (data.IsValid) {
        setPid('');
        fetchAllProjects();
        closeModal();
      }
    } catch (error) {
      console.error(error);
    }
  }

  // voice api call

  const fetchVoice = async (project_Id) => {
    const reqBody = {
      UserId: localStorage.getItem('userSessionId'),
      ProjectId: project_Id,
    };
    try {
      const response = await fetch(`${apiURL}/web/get_voices`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.IsValid) {
          localStorage.setItem('voiceList', JSON.stringify(data.VoiceList));
          setVoice1(data.VoiceList[0]);
          setVoicesArray(data.VoiceList);
        }
      } else {
        throw new Error('Failed to fetch project data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchAllProjects = async () => {
    const requestBody = {
      UserId: localStorage.getItem('userSessionId'),
      Type: 3,
    };
    try {
      const response = await fetch(projectsApiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // 'ApiKey': apiKey,
        },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();

      if (data.IsValid) {
        setMyTranscendProjects(data.ProjectList);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchAllProjects();
  }, []);

  async function getProject(id) {
    const req = {
      ProjectId: id,
    };
    try {
      const response = await fetch(`${apiURL}/web/get_project`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // 'ApiKey': apiKey,
        },
        body: JSON.stringify(req),
      });
      const data = await response.json();

      if (data.IsValid) {
        //  conditions to check project status
        if (data.Status === 2) {
          setProjectId(data.Id);
          setSelectedTranscendComponent('spectral-sync');
          setProjectName(data.Name);
          setLanguageId(data.ResultVideos[0].TargetLangId);
        } else if (data.Status === 1 || data.Status === 5) {
          // conditions to check result video status
          if (
            data.ResultVideos[0].Status === 1 ||
            data.ResultVideos[0].Status === 0
          ) {
            // proceed to speakers selection page

            setProjectId(data.Id);
            fetchVoice(data.Id);
            setProjectName(data.Name);
            setLanguageId(data.ResultVideos[0].TargetLangId);
            setVideoUrl(data.VideoUrl);
            setSelectedTranscendComponent('speaker');
          } else if (
            data.ResultVideos[0].Status === 6 ||
            data.ResultVideos[0].Status === 5
          ) {
            // proceed to transcription processing page

            setProjectId(data.Id);
            fetchVoice(data.Id);
            setProjectName(data.Name);
            setLanguageId(data.ResultVideos[0].TargetLangId);
            setVideoUrl(data.VideoUrl);
            setSelectedTranscendComponent('transcribe');
          } else if (
            data.ResultVideos[0].Status === 8 ||
            data.ResultVideos[0].Status === 7
          ) {
            // proceed to translation processing page
            setProjectId(data.Id);
            setProjectName(data.Name);
            setLanguageId(data.ResultVideos[0].TargetLangId);
            setSelectedTranscendComponent('translate');
            setVideoUrl(data.VideoUrl);
          } else if (
            data.ResultVideos[0].Status === 10 ||
            data.ResultVideos[0].Status === 9 ||
            data.ResultVideos[0].Status === 101
          ) {
            setProjectId(data.Id);
            setLanguageId(data.ResultVideos[0].TargetLangId);
            fetchVoice(data.Id);
            setSelectedTranscendComponent('audio_segments');
            setVideoUrl(data.VideoUrl);
            setProjectName(data.Name);
          } else if (data.ResultVideos[0].Status === 11) {
            // proceed to download dubbed video page

            setProjectId(data.Id);
            setLanguageId(data.ResultVideos[0].TargetLangId);
            setVideoUrl(data.VideoUrl);
            setProjectName(data.Name);
            setSelectedTranscendComponent('download');
          } else if (
            data.ResultVideos[0].Status === 13 ||
            data.ResultVideos[0].Status === 12 ||
            data.ResultVideos[0].Status === 14 ||
            data.ResultVideos[0].Status === 1499 ||
            data.ResultVideos[0].Status === 15
          ) {
            setProjectId(data.Id);
            setLanguageId(data.ResultVideos[0].TargetLangId);
            setProjectName(data.Name);
            setVideoUrl(data.VideoUrl);
            // fetchVoice(data.Id);
            setSelectedTranscendComponent('spectral-sync');
          }
        } else if (data.Status === 0 || data.Status === 5) {
          setProjectId(data.Id);
          fetchVoice(data.Id);
          setProjectName(data.Name);
          setVideoUrl(data.VideoUrl);
          setLanguageId(data.ResultVideos[0].TargetLangId);
          setSelectedTranscendComponent('sync2');
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const filteredProjects = myTranscendProjects.filter((project) =>
    project.Name.toLowerCase().includes(searchText.toLowerCase())
  );

  const getTooltipText = (langCode) => {
    const languagesList = JSON.parse(localStorage.getItem('languagesList'));
    const matchingLanguage = languagesList.find(
      (language) => language.LanguageCode === langCode
    );
    return matchingLanguage ? matchingLanguage.Name : '';
  };

  return (
    <div onMouseDown={(e) => setOpenMenuId(null)}>
      <div className="user-btns">
        <button
          onClick={() => {
            const userInfo = JSON.parse(localStorage.getItem('userInfo'));
            if (
              userInfo &&
              userInfo.LimitCredits > 0 &&
              userInfo.LimitCredits > userInfo.UsageCredits
            ) {
              setSelectedTranscendComponent('create');
            } else {
              if (userInfo.LimitCredits <= 0) {
                notify(
                  'Subscription has expired. Renew your subscription to keep using Spectral.'
                );
              } else if (userInfo.LimitCredits <= userInfo.UsageCredits) {
                notify('Insufficient credits available for project creation!');
              }
            }
          }}
          className="create-btn"
        >
          <span className="create-icon">
            <IoCut />
          </span>
          Create Project
        </button>
      </div>
      <div
        className="mt-5 ms-5 mb-3"
        style={{ display: 'flex', flexDirection: 'row' }}
      >
        <span style={{ fontWeight: 500, fontSize: '18px' }}>
          Recent Projects
        </span>
      </div>
      {filteredProjects.length === 0 && searchText !== '' ? (
        <p className="no-projects-txt">
          No projects found with the given search text.
        </p>
      ) : null}
      <div className="transcend-cards-container">
        {filteredProjects
          .filter((project) => project.Status !== 4)
          .sort((a, b) => new Date(b.CreatedAt) - new Date(a.CreatedAt))
          .slice(0, displayedCards)
          .map((projects, index) => {
            // Parse the timestamp string into a Date object
            const createdAtDate = new Date(projects.CreatedAt + 'Z');
            return (
              <Card
                className="tanscendProjectsCard mt-4 ms-5 "
                key={projects.Id}
                style={{
                  border: 'none',
                  backgroundColor: '#f7f7f9',
                }}
                onMouseEnter={(e) => {
                  const editButton =
                    e.currentTarget.querySelector('.edit-button');
                  editButton.style.opacity = 1;
                }}
                onMouseLeave={(e) => {
                  const editButton =
                    e.currentTarget.querySelector('.edit-button');
                  editButton.style.opacity = 0;
                }}
              >
                <div
                  style={{
                    position: 'relative',
                    flex: '1',
                  }}
                >
                  {projects.ThumbnailUrl !== null ? (
                    <Card.Img
                      className="transcendCardThumbnail"
                      src={projects.ThumbnailUrl}
                      alt={projects.ProjectName}
                    />
                  ) : (
                    <Card.Img
                      className="transcendCardThumbnail"
                      src={thumbnail}
                      alt={projects.ProjectName}
                    />
                  )}
                  <div
                    className="status-label"
                    style={{ position: 'absolute', top: 15, left: 10 }}
                  >
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          {getTooltipText(
                            projects.ResultVideos[0].TargetLanguageCode
                          )}
                        </Tooltip>
                      }
                    >
                      <span
                      // title={getTooltipText(projects.ResultVideos[0].TargetLanguageCode)}
                      >
                        {projects.ResultVideos[0].TargetLanguageCode}
                      </span>
                    </OverlayTrigger>
                  </div>
                  <div
                    className="edit-button"
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      opacity: 0,
                      transition: 'opacity 0.3s',
                      backgroundColor: 'rgba(0, 0, 0, 0.7)',
                      color: '#fff',
                      fontSize: '16px',
                      fontWeight: 500,
                      padding: '8px 16px',
                      borderRadius: '5px',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => getProject(projects.Id)}
                  >
                    Edit
                  </div>
                  <div
                    className="status-label"
                    style={{ position: 'absolute', bottom: 15, left: 10 }}
                  >
                    <span>
                      {projects.ResultVideos[0].Status === 1
                        ? 'Files uploaded'
                        : projects.ResultVideos[0].Status === 5
                        ? 'Speakers selected'
                        : projects.ResultVideos[0].Status === 6
                        ? 'Speakers selected'
                        : projects.ResultVideos[0].Status === 7
                        ? 'Transcription done'
                        : projects.ResultVideos[0].Status === 8
                        ? 'Transcription done'
                        : projects.ResultVideos[0].Status === 9
                        ? 'Translation done'
                        : projects.ResultVideos[0].Status === 10
                        ? 'Translation done'
                        : projects.ResultVideos[0].Status === 11
                        ? 'Dubbed video ready'
                        : projects.ResultVideos[0].Status === 12
                        ? 'LipSync video processing'
                        : projects.ResultVideos[0].Status === 13
                        ? 'LipSync video processing'
                        : projects.ResultVideos[0].Status === 14
                        ? 'LipSync video processing'
                        : projects.ResultVideos[0].Status === 1499
                        ? 'LipSync video processing'
                        : projects.ResultVideos[0].Status === 15
                        ? 'LipSync video ready'
                        : projects.ResultVideos[0].Status === 101
                        ? 'Translation done'
                        : null}
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    color: 'black',
                    fontWeight: 500,
                    marginTop: '8px',
                  }}
                >
                  <div>
                    <span
                      title={projects.Name.length > 30 ? projects.Name : null}
                    >
                      {' '}
                      {projects.Name.length > 30
                        ? projects.Name.slice(0, 30) + '...'
                        : projects.Name}
                    </span>
                    <br />
                    <span style={{ color: 'grey', fontWeight: 400 }}>
                      Created:{' '}
                      <ReactTimeAgo date={createdAtDate} timeStyle="round" />
                    </span>
                  </div>
                  {/* css for options button in usepage.css */}
                  <Button
                    variant="text"
                    style={{
                      fontSize: '25px',
                      padding: 0,
                      height: '26px',
                      border: 'none',
                      outline: 'none',
                      position: 'relative',
                    }}
                    className="optionsButton"
                    onClick={(e) => toggleOptions(projects.Id)}
                    onMouseDown={(e) => e.stopPropagation()}
                  >
                    <FiMoreVertical />
                    {openMenuId === projects.Id && (
                      <div className="options-menu">
                        <button
                          style={{ fontWeight: 500 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleShow(projects.Id);
                          }}
                        >
                          <span className="me-1">
                            <MdOutlineEdit />
                          </span>
                          Rename
                        </button>
                        {disableDownload === false ? (
                          <button
                            style={{ fontWeight: 500 }}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <a
                              style={{ textDecoration: 'none', color: 'black' }}
                              href={downloadUrl}
                            >
                              {' '}
                              <span className="me-1">
                                <GoDownload />
                              </span>
                              Download video
                            </a>
                          </button>
                        ) : (
                          <button
                            style={{
                              color: 'grey',
                              fontWeight: 500,
                              cursor: 'not-allowed',
                            }}
                            disabled
                          >
                            <span className="me-1">
                              <GoDownload />
                            </span>
                            Download video
                          </button>
                        )}
                        <button
                          style={{ fontWeight: 500 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            openModal(projects.Id);
                          }}
                        >
                          <span className="me-1">
                            <RiDeleteBin6Line />
                          </span>
                          Delete
                        </button>
                      </div>
                    )}
                  </Button>
                </div>
                <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Rename project</Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                    <Form.Label column sm="2">
                      Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Project name"
                      defaultValue={reName}
                      onBlur={(e) => {
                        setReName(e.target.value);
                      }}
                    />
                    <div className="mt-4 mb-2">
                      <Row>
                        <Col>
                          <Button
                            variant="secondary"
                            block
                            onClick={handleClose}
                            style={{ width: '100%' }}
                          >
                            Cancel
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            variant="primary"
                            block
                            onClick={(e) => {
                              handleProjectReName();
                            }}
                            style={{ width: '100%' }}
                          >
                            Save
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Modal.Body>
                </Modal>
                {/* Modal for delete project warning */}

                <Modal show={showModal} onHide={closeModal} size="md">
                  <Modal.Header closeButton>
                    <Modal.Title>Confirm delete</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Are you sure you want to delete the project
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="warning"
                      onClick={(e) => handleDeleteProject()}
                    >
                      Confirm
                    </Button>
                    <Button variant="secondary" onClick={closeModal}>
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Card>
            );
          })}
        {myTranscendProjects.length === 0 ? (
          <div className="transcend-welcome">
            <p>Welcome to Spectral. Let's create a project.</p>
          </div>
        ) : null}
      </div>
      <div
        className="mb-5"
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        {filteredProjects.length > displayedCards && (
          <Button
            style={{ fontSize: '14px', fontWeight: 500 }}
            variant="outline-primary"
            onClick={handleShowMore}
          >
            Show More
          </Button>
        )}
      </div>
      <ToastContainer />
    </div>
  );
}
