import React, { useState } from "react";
import "./audioSegments.css";
import AudioSegmentPage1 from "./AudioSegmentPage1";
import { useEffect } from "react";
import ReactGA from "react-ga";

export default function AudioSegments({
  setSelectedVoiceTransferComponent,
  projectId,
  languageId,
  setLanguageId,
  setProjectId,
  setProjectName,
  projectName,
  videoUrl,
}) {
  const apiURL = process.env.REACT_APP_API_URL;
  const [selectedSegmentComponent, setSelectedSegmentComponent] =
    useState("segment1");
  const [audioSegments, setAudioSegments] = useState([]);
  const [flag, setFlag] = useState(false);
  const [pauseGeneration, setPauseGeneration] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(null);

  // google analytics

  const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview("/spectral-transcend/audio_segments");
    ReactGA.set({ page: "/spectral-transcend/audio_segments" });
  }, []);

  useEffect(() => {
    const fetchAudioSegments = async () => {
      const reqBody = {
        ProjectId: projectId,
        // TargetLanguageId: languageId,
      };
      try {
        const response = await fetch(`${apiURL}/web/get_VT_audio_segments`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify(reqBody),
        });

        if (response.ok) {
          const newData = await response.json();

          if (newData.IsValid) {
            setCurrentStatus(newData.Status);
            if (
              newData.Status === 204 ||
              newData.Status === 2 ||
              newData.Status === 9
            ) {
              clearInterval(intervalId);
              setFlag(true);
            }
            if (newData.Status === 101 || newData.Status === 205) {
              setPauseGeneration(true);
              clearInterval(intervalId);
            }

            setAudioSegments([...(newData.ResultAudioSegments || [])]);
          }
        } else {
          throw new Error("Failed to fetch project data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchAudioSegments();
    const intervalId = setInterval(fetchAudioSegments, 5000);

    return () => clearInterval(intervalId);
  }, []);
  const downloadAllFiles = async () => {
    if (audioSegments.length > 0) {
      const urls = audioSegments.map((val) =>
        val.AudioSegmentUrl.replace(/\s/g, "")
      );

      const reqBody = { Urls: urls };

      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Origin", window.location.origin);
      fetch(`${apiURL}/web/download_and_zip`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(reqBody),
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Handle the downloaded zip file
          const url = window.URL.createObjectURL(new Blob([blob]));
          const a = document.createElement("a");
          a.href = url;
          a.download = "audio_segments.zip";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        })
        .catch((error) => console.error("Error:", error));
    }
  };

  function handleDownloadClick() {
    downloadAllFiles();
  }

  const renderSelectedSegmentComponent = () => {
    switch (selectedSegmentComponent) {
      case "segment1":
        return (
          <AudioSegmentPage1
            setSelectedSegmentComponent={setSelectedSegmentComponent}
            projectId={projectId}
            languageId={languageId}
            setLanguageId={setLanguageId}
            currentStatus={currentStatus}
            audioSegments={audioSegments}
            setAudioSegments={setAudioSegments}
            setSelectedVoiceTransferComponent={
              setSelectedVoiceTransferComponent
            }
            videoUrl={videoUrl}
            setProjectName={setProjectName}
            flag={flag}
            setFlag={setFlag}
            handleDownloadClick={handleDownloadClick}
            pauseGeneration={pauseGeneration}
            setPauseGeneration={setPauseGeneration}
            setProjectId={setProjectId}
            projectName={projectName}
            setCurrentStatus={setCurrentStatus}
          />
        );
      default:
        return null;
    }
  };

  return (
    // <div className='syncPage'>
    <div style={{ padding: "1%" }}>
      <div>{renderSelectedSegmentComponent()}</div>
    </div>
  );
}
