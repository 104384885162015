import React, { useEffect, useState } from 'react';
// import VoicePage1 from './VoicePage1';
import { Button } from 'react-bootstrap';
import { IoFilterSharp } from 'react-icons/io5';
import { FaUpload, FaGoogleDrive, FaDropbox } from 'react-icons/fa6';
import { IoMdArrowDropleft, IoMdArrowDropright } from 'react-icons/io';
import Avtar from '../../../multimedia/user-avt.png';
import { ToastContainer, toast } from 'react-toastify';

import {
  Container,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { HiInformationCircle } from 'react-icons/hi2';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';
import './spectralDRM.css';

export default function DashboardDRM({
  setSearchText,
  searchText,
  userId,
  userInfo,
  setUserInfo,
  selectedDashboardComponent,
  setSelectedDashboardComponent,
  setShowProjectsSubButtons,
  showProjectsSubButtons,
}) {
  const [activeTab, setActiveTab] = useState('textToSpeech');
  const [activeTab2, setActiveTab2] = useState('settings');
  const [consentReq, setConsentReq] = useState([]);
  const [projectList, setProjectList] = useState([]);

  // const [voicesArray, setVoicesArray] = useState([]);
  const apiURL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const InformationCircleTooltip = ({ id, text }) => (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip style={{ fontSize: '12px' }} id={`tooltip-${id}`}>
          {text}
        </Tooltip>
      }
    >
      <span
        style={{
          fontSize: '16px',
          marginTop: '12px',
          marginLeft: '6px',
          color: 'gray',
        }}
      >
        <HiInformationCircle />
      </span>
    </OverlayTrigger>
  );

  // const categoryArray = ['Category', 'Professional', 'Voice Design'];
  // const typeArray = ['All', 'Prebuilt', 'Custom'];

  // const handleCategoryChange = (event) => {
  //   const selectedCategory = event.target.value;
  //   setCategory(selectedCategory === 'Category' ? '' : selectedCategory);
  // };
  // const handleTypeChange = (event) => {
  //   const selectedType = event.target.value;
  //   setType(selectedType);
  // };

  // google analytics

  const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview('/voice_lab');
    ReactGA.set({ page: '/voice_lab' });
  }, []);

  function fetchUserInfo() {
    const reqBody = {
      UserId: localStorage.getItem('userSessionId'),
    };
    fetch(`${apiURL}/web/get_user_info`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers if needed
      },
      body: JSON.stringify(reqBody),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the data from the API
        if (data.IsValid) {
          setUserInfo(data.UserInfo);
          localStorage.setItem('userInfo', JSON.stringify(data.UserInfo));
        }
      })
      .catch((error) => {
        // Handle errors
        console.error('Error:', error);
      });
  }
  function fetchConsentRequests() {
    const reqBody = {
      UserId: localStorage.getItem('userSessionId'),
      TopN: 4,
    };
    fetch(`${apiURL}/web/get_drm_consent_requests`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers if needed
      },
      body: JSON.stringify(reqBody),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the data from the API
        if (data.IsValid) {
          setConsentReq(data.ConsentRequests);
          console.log(consentReq);
        }
      })
      .catch((error) => {
        // Handle errors
        console.error('Error:', error);
      });
  }

  const notifySuccess = (message) => {
    toast.success(message, {
      className: 'custom-success-toast',
    });
  };

  const notifyDeny = (message) => {
    toast.error(message, {
      className: 'custom-success-toast1',
    });
  };

  const handleApproveConsentRequest = async (id, index) => {
    const reqBody = {
      UserId: localStorage.getItem('userSessionId'),
      ConsentId: id,
      ConsentStatus: 1,
    };
    try {
      const response = await fetch(`${apiURL}/web/update_drm_consent_request`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.IsValid) {
          notifySuccess('Approved');
        }
      } else {
        throw new Error('Unsuccessful');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const handleDenyConsentRequest = async (id, index) => {
    const reqBody = {
      UserId: localStorage.getItem('userSessionId'),
      ConsentId: id,
      ConsentStatus: 2,
    };
    try {
      const response = await fetch(`${apiURL}/web/update_drm_consent_request`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.IsValid) {
          notifyDeny('Denied');
          console.log(id);
        }
      } else {
        throw new Error('Unsuccessful');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getDrmProjects = async () => {
    const reqBody = {
      UserId: localStorage.getItem('userSessionId'),
      TopN: 4,
    };
    try {
      const response = await fetch(`${apiURL}/web/get_drm_projects`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.IsValid) {
          setProjectList(data.ProjectList);
          console.log(projectList);
        }
      } else {
        throw new Error('Failed to fetch project data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleViewClick = () => {
    navigate('/projects');
    setShowProjectsSubButtons(!showProjectsSubButtons);
  };

  const scrollRef = React.createRef();

  const scroll = (scrollOffset) => {
    scrollRef.current.scrollLeft += scrollOffset;
  };

  useEffect(() => {
    document.title = 'Dashboard - Spectral Studio';
    fetchUserInfo();
    fetchConsentRequests();
    getDrmProjects();
    // getVoices();
    // setSearchText('');
  }, []);
  useEffect(() => {
    // Update userInfo state whenever localStorage userInfo changes
    setUserInfo(JSON.parse(localStorage.getItem('userInfo')));
  }, [localStorage.getItem('userInfo')]);

  return (
    <div className="dashboard-creator-container">
      <header>
        <h2 className="">Dashboard</h2>
        {/* <p>
          Unleash the power of our cutting-edge technology to generate
          realistic, captivating speech in a wide range of languages.
        </p> */}
      </header>
      <div className="dashboard-content">
        <div className="dashboard-left">
          {userInfo.ProfileImage !== null ? (
            <img
              style={{ height: '100%', objectFit: 'cover' }}
              src={userInfo.ProfileImage}
              className="avtar-img"
            ></img>
          ) : (
            <img src={Avtar} className="avtar-img"></img>
          )}
        </div>

        <div className="dashboard-right">
          <div className="dashboard-Consent">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {' '}
              <span
                style={{
                  fontSize: '18px',
                  fontWeight: '500',
                  background: '#fff',
                }}
                className=""
              >
                Consent Requests
              </span>
              <button
                style={{
                  textDecoration: 'none',
                  background: 'none',
                  border: 'none',
                  padding: 0,
                  cursor: 'pointer',
                  color: 'blue',
                }}
              >
                View
              </button>
              {/* <Button style={{ backgroundColor: 'black', border: 'none' }}>
              Add New +
            </Button> */}
            </div>
            <div
              className="new-projects-container"
              style={{ marginTop: '1rem', listStyle: 'none' }}
            >
              {consentReq.map((item, index) => (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                  className="recent-list-projects"
                  key={item.ConsentId}
                >
                  <div>{item.ProjectName}</div>
                  <div>
                    <Button
                      onClick={(e) =>
                        handleApproveConsentRequest(item.ConsentId, index)
                      }
                      style={{
                        marginRight: '1rem',
                        backgroundColor: 'green',
                        border: 'none',
                      }}
                      size="sm"
                    >
                      Approve
                    </Button>
                    <Button
                      onClick={(e) =>
                        handleDenyConsentRequest(item.ConsentId, index)
                      }
                      style={{
                        marginRight: '1rem',
                        backgroundColor: 'red',
                        border: 'none',
                      }}
                      size="sm"
                    >
                      Deny
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="dashboard-recent-projects">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {' '}
              <span
                style={{
                  fontSize: '18px',
                  fontWeight: '500',
                  background: '#fff',
                }}
                className=""
              >
                Recent Projects
              </span>
              <button
                onClick={handleViewClick}
                style={{
                  textDecoration: 'none',
                  background: 'none',
                  border: 'none',
                  padding: 0,
                  cursor: 'pointer',
                  color: 'blue',
                }}
              >
                View
              </button>
              {/* <Button style={{ backgroundColor: 'black', border: 'none' }}>
              Add New +
            </Button> */}
            </div>

            <div
              className="new-projects-container"
              style={{ marginTop: '1rem' }}
            >
              {projectList.map((item, index) => (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                  className="recent-list-projects"
                  key={index}
                >
                  <div>{item.ProjectName}</div>
                  <div>
                    <Button
                      onClick={handleViewClick}
                      style={{
                        marginRight: '1rem',
                        backgroundColor: 'blue',
                        border: 'none',
                      }}
                      size="sm"
                    >
                      View
                    </Button>
                  </div>
                </div>
              ))}
            </div>

            {/* ------------- */}

            {/* <div className="scroll-container">
              <button
                className="scroll-button-left"
                onClick={() => scroll(-200)}
              >
                <IoMdArrowDropleft />
              </button>
              <div className="scroll-wrapper" ref={scrollRef}>
                {dummyData.map((item, index) => (
                  <div className="scroll-item" key={index}>
                    <p>{item.title}</p>
                  </div>
                ))}
              </div>
              <button
                className="scroll-button-right"
                onClick={() => scroll(200)}
              >
                <IoMdArrowDropright />
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
