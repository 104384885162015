import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";

export default function PersonalizePage2({ setSelectedPersonalizeComponent }) {
  const [selectedWords, setSelectedWords] = useState([]);
  const [flag, setFlag] = useState(1);
  const details = ["First Name", "Last Name", "City"];
  const defaultVal = `Hi Scott Hansen ,

Did you know that astronauts actually grow taller in space ?

It might sound strange , but it's true !

When astronauts spend extended periods in microgravity ,
the absence of gravity allows the spaces between their vertebrae to expand ,
causing them to gain a few inches in height .

So , if you ever find yourself in a conversation with an astronaut in your city of Mumbai ,
you can ask them if they've ever experienced a sudden growth spurt in space !`;

  const handleWordSelect = (word, index) => {
    if (selectedWords.length < 3) {
      const isWordAlreadySelected = selectedWords.some(
        (selectedWord) => selectedWord.word === word
      );
      if (!isWordAlreadySelected) {
        setSelectedWords([...selectedWords, { word, index }]);
        setFlag(1);
      }
    }
  };

  const handleWordDeselect = (index) => {
    setSelectedWords((prevSelectedWords) => {
      const updatedWords = prevSelectedWords.filter(
        (selectedWord) => selectedWord.index !== index
      );
      if (updatedWords.length === 0) {
        setFlag(0);
      }
      return updatedWords;
    });
  };

  // Split the text into paragraphs
  const paragraphs = defaultVal.split("\n");

  return (
    <div className="spectralPersonalize2">
      <div className="spectralP2">
        <div className="personalize2">
          <div
            style={{
              backgroundColor: "white",
              padding: "30px",
              borderRadius: "8px",
              border: "1px solid lightgrey",
            }}
          >
            {paragraphs.map((paragraph, paragraphIndex) => (
              <div key={paragraphIndex} className="selectableWord">
                {paragraph.split(" ").map((word, wordIndex) => (
                  <span
                    key={wordIndex}
                    className="words"
                    onClick={() => handleWordSelect(word, wordIndex)}
                  >
                    {word}{" "}
                  </span>
                ))}
              </div>
            ))}
          </div>

          <p className="mt-4">
            Click up to 3 words above to add to personalization
          </p>
          <div className="mt-4">
            {selectedWords.map((selectedWord, idx) => (
              <div className="selected-words" key={idx}>
                <Form.Control
                  type="text"
                  value={selectedWord.word}
                  readOnly
                  style={{ width: "300px" }}
                />
                <Form.Select style={{ width: "300px" }}>
                  <option>{details[idx]}</option>
                </Form.Select>
                <Button onClick={() => handleWordDeselect(selectedWord.index)}>
                  Deselect
                </Button>
              </div>
            ))}
          </div>
          {flag === 0 ? (
            <p style={{ color: "red" }}>* Select atleast one word</p>
          ) : null}
          <div
            className="mt-5"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <button
              className="nxtBtn"
              onClick={(e) => {
                if (selectedWords.length > 0) {
                  setSelectedPersonalizeComponent("personalize3");
                } else {
                  setFlag(0);
                }
              }}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
