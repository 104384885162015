import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";

export default function PersonalizePage3() {
  const [display, setDisplay] = useState(false);
  return (
    <div className="spectralPersonalize3">
      <div className="spectralPersonalizeCommon">
        <div className="personalize3">
          {display === true ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>City</th>
                  <th>Status</th>
                  <th>Link</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>Mumbai</td>
                  <td>Completed</td>
                  <td>
                    <a href="#">View</a> | <a href="#">Download</a>
                  </td>
                </tr>
                <tr>
                  <td>Jacob</td>
                  <td>Thornton</td>
                  <td>Mumbai</td>
                  <td>Completed</td>
                  <td>
                    <a href="#">View</a> | <a href="#">Download</a>
                  </td>
                </tr>
                <tr>
                  <td>Larry</td>
                  <td>Bird</td>
                  <td>Mumbai</td>
                  <td>Completed</td>
                  <td>
                    <a href="#">View</a> | <a href="#">Download</a>
                  </td>
                </tr>
                <tr>
                  <td>Jacob</td>
                  <td>Otto</td>
                  <td>Delhi</td>
                  <td>In Progress</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Mark</td>
                  <td>Bird</td>
                  <td>Delhi</td>
                  <td>Pending</td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
          ) : (
            <Table striped bordered hover className="emptyTable">
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>City</th>
                  <th>Status</th>
                  <th>Link</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>Mumbai</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Jacob</td>
                  <td>Thornton</td>
                  <td>Mumbai</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Larry</td>
                  <td>Bird</td>
                  <td>Mumbai</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Jacob</td>
                  <td>Otto</td>
                  <td>Delhi</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Mark</td>
                  <td>Bird</td>
                  <td>Delhi</td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
          )}
          {display === true ? (
            <p className="mt-3">
              Video Personalizations are in progress. The grid will auto-refresh
              periodically to reflect the status for each contact.
            </p>
          ) : null}
          {display === false ? (
            <div
              className="mt-3"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Button onClick={(e) => setDisplay(true)}>
                Start Personalizations
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
