import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FaDownload } from "react-icons/fa6";
import load from "../../../multimedia/load2.png";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ReactGA from "react-ga";
import { IoArrowBack } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function SpectralSyncVideo({
  videoUrl,
  languageId,
  setLanguageId,
  projectId,
  setVideoUrl,
  setProjectId,
  setSelectedTranscendComponent,
  setProjectName,
}) {
  const apiURL = process.env.REACT_APP_API_URL;
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("");
  const [targetLanguage, setTargetLanguage] = useState("");
  const [syncUrl, setSyncUrl] = useState("");
  const [dubbedUrl, setDubbedUrl] = useState("");
  const [activeTab, setActiveTab] = useState("original");
  const originalVideoRef = useRef(null);
  const dubbedVideoRef = useRef(null);

  // google analytics

  const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview("/spectral-transcend/spectral_sync");
    ReactGA.set({ page: "/spectral-transcend/spectral_sync" });
  }, []);

  const notify = (error) =>
    toast.error(error, {
      className: "custom-error-toast",
    });

  const handleTabSelect = (key) => {
    if (activeTab === "original" && originalVideoRef.current) {
      originalVideoRef.current.pause();
    } else if (activeTab === "dubbed" && dubbedVideoRef.current) {
      dubbedVideoRef.current.pause();
    }
    setActiveTab(key);
  };

  useEffect(() => {
    async function getProject() {
      const req = {
        ProjectId: projectId,
      };
      // console.log('required req is',req);
      try {
        const response = await fetch(`${apiURL}/web/get_project`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // 'ApiKey': apiKey,
          },
          body: JSON.stringify(req),
        });
        const data = await response.json();

        if (data.IsValid) {
          setLanguageId(data.ResultVideos[0].TargetLangId);
          setVideoUrl(data.VideoUrl);
          setDubbedUrl(data.ResultVideos[0].DubbedVideoUrl);
          setName(data.Name);
          setTargetLanguage(data.ResultVideos[0].TargetLangName);
          setStatus(data.ResultVideos[0].Status);
          setProjectId(data.Id);
          if (data.ResultVideos[0].Status === 15) {
            clearInterval(intervalId);
            setSyncUrl(data.ResultVideos[0].SpectralSyncedVideoUrl);
            setLoading(false);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    getProject();
    const intervalId = setInterval(getProject, 60000);

    return () => clearInterval(intervalId);
  }, []);

  async function change_project_result_status() {
    const req = {
      ProjectId: projectId,
      CurrentStatus: status,
      TargetStatus: 11,
    };
    try {
      const response = await fetch(
        `${apiURL}/web/change_project_result_status`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // 'ApiKey': apiKey,
          },
          body: JSON.stringify(req),
        }
      );
      const res = await response.json();

      if (res.IsValid) {
        setProjectId(projectId);
        setLanguageId(languageId);
        setVideoUrl(videoUrl);
        setProjectName(name);
        setSelectedTranscendComponent("download");
      } else {
        notify("Redirection to previous stage was unsuccessful");
      }
    } catch (error) {
      console.error(error);
      notify("Redirection to previous stage was unsuccessful");
    }
  }

  function back() {
    // console.log('back clicked');
    change_project_result_status();
  }

  return (
    // <div className='syncPage' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '70vh' }}>
    <div style={{ padding: "1%" }}>
      <Row>
        <Col md={12}>
          <div
            className="pg4"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <span>
              <span style={{ position: "absolute", left: 10, top: 10 }}>
                <Button
                  style={{ fontSize: "20px", fontWeight: 500 }}
                  variant="text"
                  onClick={(e) => back()}
                >
                  <IoArrowBack />
                  <span className="ms-1" style={{ fontSize: "14px" }}>
                    Back
                  </span>
                </Button>
              </span>
            </span>
            <Row>
              <Col md={6} sm={12}>
                <div>
                  <table>
                    <tr>
                      <td style={{ fontWeight: 500 }}>Project Name :</td>
                      <td style={{ paddingLeft: "20px" }}>{name}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: 500 }}>Target Language :</td>
                      <td style={{ paddingLeft: "20px" }}>{targetLanguage}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: 500 }}>Status :</td>
                      <td style={{ paddingLeft: "20px" }}>
                        {status === 12
                          ? "Requested"
                          : status === 13
                          ? "Pending"
                          : status === 14
                          ? "Processing"
                          : status === 1499
                          ? "Processing"
                          : "Ready"}
                      </td>
                    </tr>
                  </table>
                  <Tabs
                    defaultActiveKey="original"
                    onSelect={(key) => {
                      handleTabSelect(key);
                    }}
                    id="oVid"
                    className="mb-3 mt-3"
                    style={{ borderBottom: "none" }}
                  >
                    <Tab eventKey="original" title="Original Video">
                      <video
                        className="original-video"
                        src={videoUrl}
                        controls
                        ref={originalVideoRef}
                      />
                    </Tab>
                    <Tab eventKey="dubbed" title="Dubbed Video">
                      <video
                        className="original-video"
                        src={dubbedUrl}
                        controls
                        ref={dubbedVideoRef}
                      />
                    </Tab>
                  </Tabs>
                </div>
              </Col>
              <Col md={6} sm={12}>
                <div className="syncVideo-div">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: -8,
                    }}
                  >
                    <h5>Spectral Video</h5>
                    {loading === true && syncUrl === "" ? (
                      <Button
                        variant="text"
                        style={{
                          padding: 0,
                          fontSize: "25px",
                          paddingBottom: "10px",
                          border: "none",
                        }}
                        disabled
                      >
                        <FaDownload />
                      </Button>
                    ) : (
                      <a href={syncUrl} style={{ textDecoration: "none" }}>
                        <Button
                          variant="text"
                          style={{
                            padding: 0,
                            fontSize: "25px",
                            paddingBottom: "10px",
                            border: "none",
                          }}
                        >
                          <FaDownload />
                        </Button>
                      </a>
                    )}
                  </div>
                  <div>
                    {loading === true && syncUrl === "" ? (
                      <div>
                        {" "}
                        <video
                          className="spectral-video"
                          controls
                          poster={load}
                        />
                        <p className="info-txt" style={{ textAlign: "center" }}>
                          LipSync is in progress and can take upto 48 hours.
                          You'll be notified via email once it's ready.
                        </p>
                      </div>
                    ) : (
                      <video
                        className="mt-2"
                        src={syncUrl}
                        style={{ width: "100%" }}
                        controls
                      />
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );
}
