import React, { useEffect, useState} from 'react';
import { Button, Card } from 'react-bootstrap';
import { HiScissors,HiMiniSignal } from 'react-icons/hi2';
import { FiMoreVertical} from 'react-icons/fi';
import {IoChevronForwardOutline} from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import {IoCut} from 'react-icons/io5';
import {BsCameraVideo,BsMic} from 'react-icons/bs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RecentProjects = () => {
  const [videos, setVideos] = useState([]);
  const notify = () => toast.info("Feature coming soon !!!",{
    style:{backgroundColor:'#ecf1ff',color:'#3498db',fontWeight:'bolder',fontSize:'20px'}
  });
  const apiKey = 'fTj2NO2kZFTkNnrk9FLHz8dpMidvGbtBy3feCBOuorkVrGyRcX3o2AOd';
  const apiURL = `https://api.pexels.com/videos/search?query=mountains&per_page=3&page=1`;
  const navigate= useNavigate();
  const fetchVideos = async () => {
    try {
      const response = await fetch(apiURL, {
        headers: {
          Authorization: apiKey,
        },
      });
      const data = await response.json();
      setVideos(data.videos);
    } catch (error) {
      console.error(error);
    }
  };
 
useEffect(()=>{
  fetchVideos();

},[])
  const handleThumbnailClick = (videoUrl) => {
   
    const iframe = document.createElement('iframe');
    iframe.src = videoUrl;
    iframe.width = 900;
    iframe.height = 600;
    const newTab = window.open();
    newTab.document.body.appendChild(iframe);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedTime = `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    return formattedTime;
  };

  

  return (
    <div>

<h2 className='intro-text'>Let's create some <span style={{fontWeight:700}}>Videos!</span></h2>
     

<div className='user-btns'>
          <button onClick={()=>navigate("/editor")} className='create-btn'><span className='create-icon' ><IoCut/></span>Create Project</button>
          <button className='record-btn' onClick={(e) => notify()}><span className='record-icon'><BsCameraVideo/></span>Record Video</button>
          <button className='golive-btn' onClick={(e) => notify()}><span className='golive-icon'><HiMiniSignal/></span>Go Live</button>
          <button className='podcast-btn' onClick={(e) => notify()}><span className='podcast-icon'><BsMic/></span>Record Podcast</button>
        </div>

        <div  className='mt-5 ms-5 mb-3' style={{display:'flex',flexDirection:'row'}}>
        <span style={{fontWeight:500,fontSize:'18px'}}>Recent Projects</span>
       <button className='allvid-btn'>All Videos<span style={{marginLeft:'6px'}}><IoChevronForwardOutline/></span></button>

     
        </div>

 <ToastContainer />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          flexWrap: 'wrap',
          // justifyContent:'space-around',
          marginBottom:'30px',
          // overflowY:'auto',
          // maxHeight:'40vh'
        }}
      >
        {videos.map((video, index) => (
         
          <Card
          className='recentProjectsCard mt-4 ms-5'
            key={video.id}
            style={{
                     border:'none',
                     backgroundColor:'#f7f7f9',              
            }}
            onMouseEnter={(e) => {
              const editButton = e.currentTarget.querySelector('.edit-button');
              editButton.style.opacity = 1;
              // Add the new className option here
              const optionsButton = e.currentTarget.querySelector('.options-button');
              optionsButton.style.opacity = 1;
            }}
            onMouseLeave={(e) => {
              const editButton = e.currentTarget.querySelector('.edit-button');
              editButton.style.opacity = 0;
              // Add the new className option here
              const optionsButton = e.currentTarget.querySelector('.options-button');
              optionsButton.style.opacity = 0;
            }}
          >
             <div
              style={{
                position: 'relative', // Add this line
                flex: '1', // Expand to fill available space
              }}
            >
            <Card.Img
            className='recentProjectsThumbnail'
              src={video.image}
              alt={video.url}
              onClick={() => handleThumbnailClick(video.video_files[0].link)}
             
            />
            
             <div
              className="edit-button"
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                opacity: 0, // Initially hidden
                transition: 'opacity 0.3s',
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                color: '#fff',
                fontSize: '16px',
                fontWeight: 500,
                padding: '8px 16px',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
              // onClick={() => console.log('clicked')}
            >
              <HiScissors /> Edit
            </div>
            </div>
            <Card.Text style={{display:'flex',justifyContent:'space-between',color:'black',fontWeight:500,marginTop:'8px'}}><p style={{marginLeft:'20px'}}>Project Name - {index + 1}<br/><span style={{color:'grey',fontWeight:400}}>Last Modified: 23 hours ago</span></p>
            <Button variant='text' style={{fontSize:'25px',padding:0,height:'26px',border:'none',outline:'none'}} className='options-button'><FiMoreVertical/></Button> </Card.Text>
           
          </Card>
          
       
        ))}
      </div>
    </div>
  );
};

export default RecentProjects;
