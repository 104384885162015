import React, { useEffect, useState } from 'react';
import './admin.css';
import UserList from './UserList';
import ManageLipSyncRequests from './ManageLipSyncRequests';
import EditUser from './EditUser';
import ManageUserProjects from './ManageUserProjects';
import { Button } from 'react-bootstrap';
import { IoFilterSharp } from 'react-icons/io5';

export default function Admin({
  selectedAdminComponent,
  setSelectedAdminComponent,
  searchText,
  setSearchText,
}) {
  const [userList, setUserList] = useState([]);
  const [filteredUserList, setFilteredUserList] = useState([]);
  const [user, setUser] = useState({});
  const [filterOn, setFilterOn] = useState(false);
  const [addUserClicked, setAddUserClicked] = useState(false)

  useEffect(() => {
    document.title = 'Admin - Spectral Studio';
  }, []);

  const renderSelectedAdminComponent = () => {
    switch (selectedAdminComponent) {
      case 'manage-users':
        return (
          <UserList
            searchText={searchText}
            userList={userList}
            setUserList={setUserList}
            filteredUserList={filteredUserList}
            setFilteredUserList={setFilteredUserList}
            user={user}
            setUser={setUser}
            addUserClicked={addUserClicked}
            setAddUserClicked={setAddUserClicked}
            setSelectedAdminComponent={setSelectedAdminComponent}
          />
        );
      case 'manage-lip-sync-requests':
        return (
          <ManageLipSyncRequests searchText={searchText} filterOn={filterOn} />
        );
      case 'manage-projects':
        return (
          <ManageUserProjects searchText={searchText} filterOn={filterOn} />
        );
      case 'user-edit':
        return (
          <EditUser
            filteredUserList={filteredUserList}
            setFilteredUserList={setFilteredUserList}
            user={user}
            setUser={setUser}
            addUserClicked={addUserClicked}
            setAddUserClicked={setAddUserClicked}
            setSelectedAdminComponent={setSelectedAdminComponent}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="admin-home">
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <h2 className="mt-2 ms-5">Admin</h2>
        {selectedAdminComponent === 'manage-lip-sync-requests' ||
        selectedAdminComponent === 'manage-projects' ? (
          <Button
            className="ms-auto me-5"
            style={{
              backgroundColor: 'transparent',
              color: 'black',
              height: '60px',
              border: '1px solid black',
              marginTop: '10px',
            }}
            onClick={(e) => {
              setFilterOn(!filterOn);
            }}
          >
            <span style={{ fontSize: '20px' }}>
              <IoFilterSharp />
            </span>
            <p style={{ fontSize: '12px' }}>filters</p>
          </Button>
        ) : null}
      </div>
      {renderSelectedAdminComponent()}
    </div>
  );
}
