import React, { useState, useEffect, useRef } from "react";
import { Container, Table } from "react-bootstrap";
import { IoReloadCircleOutline } from "react-icons/io5";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import loading from "../../../multimedia/loading.gif";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ReactGA from "react-ga";

export default function TranslateResult({
  selectedTranslateComponent,
  projectId,
  setProjectId,
  setDubbedData,
  dubbedData,
  pName,
  setPName,
  processingData,
  setProcessingData,
}) {
  const apiURL = process.env.REACT_APP_API_URL;
  const [dubbingStatus, setDubbingStatus] = useState(null);
  const now = localStorage.getItem("duration");
  const [show, setShow] = useState(false);
  const [flag, setFlag] = useState(false);
  const [viewUrl, setViewUrl] = useState("");
  const [viewLanguage, setViewLanguage] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [languageList, setLanguageList] = useState([]);
  const [seconds, setSeconds] = useState(0);
  const [originalVideo, setOriginalVideo] = useState("");
  const [activeTab, setActiveTab] = useState("original");
  const [activeDubTab, setActiveDubTab] = useState("original");
  const originalVideoRef = useRef(null);
  const dubbedVideoRefs = useRef({});
  const originalProcessingVideoRef = useRef(null);
  const dubbedProcessingVideoRefs = useRef({});
  const [delay, setDelay] = useState(0);

  // google analytics

  const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview("/spectral-translate/dubbed_results");
    ReactGA.set({ page: "/spectral-translate/dubbed_results" });
  }, []);

  const handleTabSelect = (key) => {
    let targetLang = "";
    if (key === "original") {
      targetLang = key;
    } else {
      const dubbedItem = dubbedData.find((item) => item.TargetLangName === key);
      if (dubbedItem) {
        targetLang = dubbedItem.TargetLangName;
      }
    }

    if (activeTab === "original" && originalVideoRef.current) {
      originalVideoRef.current.pause();
    } else if (activeTab !== "original" && dubbedVideoRefs.current[activeTab]) {
      dubbedVideoRefs.current[activeTab].pause();
    }

    setActiveTab(targetLang);
  };

  const handleProcessingTabSelect = (key) => {
    let targetLang = "";
    if (key === "original") {
      targetLang = key;
    } else {
      const dubbedItem = processingData.find(
        (item) => item.TargetLangName === key
      );
      if (dubbedItem) {
        targetLang = dubbedItem.TargetLangName;
      }
    }

    if (activeDubTab === "original" && originalProcessingVideoRef.current) {
      originalProcessingVideoRef.current.pause();
    } else if (
      activeDubTab !== "original" &&
      dubbedProcessingVideoRefs.current[activeDubTab]
    ) {
      dubbedProcessingVideoRefs.current[activeDubTab].pause();
    }

    setActiveDubTab(targetLang);
  };

  function viewProject(url, language) {
    handleShow();
    setViewUrl(url);
    setViewLanguage(language);
  }

  function fetchLanguageName() {
    setLanguageList(JSON.parse(localStorage.getItem("languagesList")));
  }

  useEffect(() => {
    fetchLanguageName();
    if (flag === true && dubbedData.length > 0) {
      setFlag(false);
    }
  }, [flag]);

  // function for getProject api call
  const fetchDataAndUpdateState = async () => {
    try {
      const response = await fetch(`${apiURL}/web/get_project`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ProjectId: projectId,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setFlag(false);
        if (data.IsValid) {
          setOriginalVideo(data.VideoUrl);
          setDubbingStatus(data.Status);
          setDelay(
            convertToLocaleTime(
              new Date(data.ResultVideos[0].SpectralTranslateETA)
            )
          );
          if (data.Status === 2) {
            setFlag(false);
            setPName(data.Name);
            setDubbedData(data.ResultVideos);
            setProcessingData([]);
          } else {
            setProcessingData(data.ResultVideos);
          }
        }
      } else {
        throw new Error("Failed to fetch project data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Time zone conversion function
  function convertToLocaleTime(date) {
    var newDate = new Date(date + "Z");
    var currentDate = new Date();
    // console.log("new time eta ",newDate)
    var timeDifference = currentDate.getTime() - newDate.getTime();
    // Convert milliseconds to seconds
    var timeDifferenceInSeconds = Math.abs(timeDifference) / 1000;
    // console.log(timeDifferenceInSeconds,' is the time difference')
    return timeDifferenceInSeconds;
  }

  useEffect(() => {
    // Exit the useEffect if the timer reaches the total duration
    if (dubbedData.length > 0) return;
    // Set up an interval to increment the timer every second
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds + 1);
    }, 1000);

    // Clean up the interval when the component unmounts or when the timer reaches the total duration
    return () => clearInterval(timer);
  }, []);

  let interval;
  useEffect(() => {
    if (dubbedData.length > 0) {
      fetchDataAndUpdateState();
    }

    if (
      dubbedData.length === 0 &&
      selectedTranslateComponent === "translate2"
    ) {
      fetchDataAndUpdateState();

      const timeout = window.setTimeout(function () {
        fetchDataAndUpdateState();

        interval = setInterval(function () {
          if (dubbedData.length > 0) {
            clearInterval(interval);
          } else {
            fetchDataAndUpdateState();
          }
        }, 5000);

        if (dubbedData.length > 0) {
          clearInterval(interval);
        }
      }, delay);

      return () => {
        clearTimeout(timeout);
        clearInterval(interval);
      };
    }
  }, [projectId, dubbedData.length, selectedTranslateComponent]);

  function refresh() {
    setFlag(true);
    fetchDataAndUpdateState();
  }

  // Function to format time in minutes and seconds
  function formatTime(timeInSeconds) {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;

    if (minutes > 0) {
      return `${minutes}min ${seconds}s remaining`;
    } else {
      return `${seconds}s remaining`;
    }
  }

  return (
    // <div className='translatePage' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
    <div
      style={{
        padding: "1%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="translatePage2">
        {pName !== "" ? <h5 className="mb-5">{pName}</h5> : null}

        {dubbedData.length > 0 && dubbedData.length >= 5 ? (
          <Table striped bordered hover>
            <thead style={{ textAlign: "center" }}>
              <tr>
                <th>Language</th>
                <th>Status</th>
                <th>Link</th>
              </tr>
            </thead>
            <tbody>
              {dubbedData.map((data, index) => (
                <tr key={index}>
                  <td style={{ textAlign: "left", width: "33%" }}>
                    {languageList.find((lang) => lang.Id === data.TargetLangId)
                      ?.Name || data.TargetLangId}
                  </td>
                  <td style={{ textAlign: "left" }}>
                    {data.Status === 2 ? (
                      <span className="ready">Ready</span>
                    ) : data.Status === 3 ? (
                      <span>
                        <span>Error</span>
                      </span>
                    ) : (
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "12px",
                            fontWeight: 500,
                            color: "grey",
                          }}
                        >
                          {convertToLocaleTime(
                            new Date(data.SpectralTranslateETA)
                          ) ? (
                            Math.round(
                              convertToLocaleTime(
                                new Date(data.SpectralTranslateETA)
                              )
                            ) -
                              seconds >=
                            0 ? (
                              <span
                                style={{
                                  fontSize: "12px",
                                  fontWeight: 500,
                                  color: "grey",
                                }}
                              >
                                {convertToLocaleTime(
                                  new Date(data.SpectralTranslateETA)
                                )
                                  ? Math.round(
                                      convertToLocaleTime(
                                        new Date(data.SpectralTranslateETA)
                                      )
                                    ) -
                                      seconds >=
                                    0
                                    ? formatTime(
                                        Math.round(
                                          convertToLocaleTime(
                                            new Date(data.SpectralTranslateETA)
                                          )
                                        ) - seconds
                                      )
                                    : "Almost there..."
                                  : (index + 1) * 5 - seconds >= 0
                                  ? formatTime((index + 1) * 5 - seconds)
                                  : "Almost there"}
                              </span>
                            ) : (
                              "Almost there..."
                            )
                          ) : (index + 1) * 5 - seconds >= 0 ? (
                            formatTime((index + 1) * 5 - seconds)
                          ) : (
                            "Almost there..."
                          )}
                        </span>
                        <meter
                          style={{ width: "200px" }}
                          value={seconds}
                          max={
                            convertToLocaleTime(
                              new Date(data.SpectralTranslateETA)
                            )
                              ? Math.round(
                                  convertToLocaleTime(
                                    new Date(data.SpectralTranslateETA)
                                  )
                                )
                              : (index + 1) * 5
                          }
                        ></meter>
                      </span>
                    )}
                  </td>

                  {data.DubbedVideoUrl !== "" ? (
                    <td style={{ textAlign: "center", width: "33%" }}>
                      <button
                        className="viewDownloadButton"
                        onClick={() => {
                          viewProject(
                            data.DubbedVideoUrl,
                            languageList.find(
                              (lang) => lang.Id === data.TargetLangId
                            )?.Name || data.TargetLangId
                          );
                        }}
                      >
                        View
                      </button>
                      <button className="viewDownloadButton ms-2">
                        {" "}
                        <a
                          style={{ textDecoration: "none", color: "grey" }}
                          href={data.DubbedVideoUrl}
                        >
                          Download
                        </a>
                      </button>
                    </td>
                  ) : null}
                </tr>
              ))}
            </tbody>
          </Table>
        ) : dubbedData.length > 0 && dubbedData.length < 5 ? (
          <Container
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Tabs
              defaultActiveKey="original"
              id="uncontrolled-tab-example"
              className="mb-3"
              onSelect={(key) => {
                handleTabSelect(key);
              }}
            >
              <Tab eventKey="original" title="Original video">
                <video
                  className="original-video-translate"
                  src={originalVideo}
                  controls
                  ref={originalVideoRef}
                />
              </Tab>
              {dubbedData.map((data) => (
                <Tab eventKey={data.TargetLangName} title={data.TargetLangName}>
                  <video
                    className="original-video-translate"
                    src={data.DubbedVideoUrl}
                    controls
                    ref={(ref) =>
                      (dubbedVideoRefs.current[data.TargetLangName] = ref)
                    }
                  />
                </Tab>
              ))}
            </Tabs>
          </Container>
        ) : dubbedData.length === 0 && processingData.length < 5 ? (
          <Container
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Tabs
              defaultActiveKey="original"
              id="uncontrolled-tab-example"
              className="mb-3"
              onSelect={(key) => {
                handleProcessingTabSelect(key);
              }}
            >
              <Tab eventKey="original" title="Original video">
                <video
                  className="original-video-translate"
                  src={originalVideo}
                  controls
                  ref={originalProcessingVideoRef}
                />
              </Tab>
              {processingData.map((data, index) => (
                <Tab eventKey={data.TargetLangName} title={data.TargetLangName}>
                  {data.Status === 2 ? (
                    <video
                      className="original-video-translate"
                      src={data.DubbedVideoUrl}
                      controls
                      ref={(ref) =>
                        (dubbedProcessingVideoRefs.current[
                          data.TargetLangName
                        ] = ref)
                      }
                    />
                  ) : (
                    <div
                      className="original-video-translate"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "20px",
                        flexDirection: "column",
                      }}
                    >
                      <div>
                        <img
                          style={{ width: "100px", height: "100px" }}
                          src={loading}
                          alt="loading"
                        />
                      </div>
                      <div
                        className="mt-2"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "12px",
                            fontWeight: 500,
                            color: "grey",
                            textAlign: "center",
                          }}
                        >
                          {convertToLocaleTime(
                            new Date(data.SpectralTranslateETA)
                          ) ? (
                            Math.round(
                              convertToLocaleTime(
                                new Date(data.SpectralTranslateETA)
                              )
                            ) -
                              seconds >=
                            0 ? (
                              <span
                                style={{
                                  fontSize: "12px",
                                  fontWeight: 500,
                                  color: "grey",
                                }}
                              >
                                {convertToLocaleTime(
                                  new Date(data.SpectralTranslateETA)
                                )
                                  ? Math.round(
                                      convertToLocaleTime(
                                        new Date(data.SpectralTranslateETA)
                                      )
                                    ) -
                                      seconds >=
                                    0
                                    ? formatTime(
                                        Math.round(
                                          convertToLocaleTime(
                                            new Date(data.SpectralTranslateETA)
                                          )
                                        ) - seconds
                                      )
                                    : "Almost there..."
                                  : (index + 1) * 5 - seconds >= 0
                                  ? formatTime((index + 1) * 5 - seconds)
                                  : "Almost there"}
                              </span>
                            ) : (
                              "Almost there..."
                            )
                          ) : (index + 1) * 5 - seconds >= 0 ? (
                            formatTime((index + 1) * 5 - seconds)
                          ) : (
                            "Almost there..."
                          )}
                        </span>
                        <meter
                          style={{ width: "200px" }}
                          value={seconds}
                          max={
                            convertToLocaleTime(
                              new Date(data.SpectralTranslateETA)
                            )
                              ? Math.round(
                                  convertToLocaleTime(
                                    new Date(data.SpectralTranslateETA)
                                  )
                                )
                              : (index + 1) * 5
                          }
                        ></meter>
                      </div>
                    </div>
                  )}
                </Tab>
              ))}
            </Tabs>
          </Container>
        ) : dubbedData.length === 0 && processingData.length >= 5 ? (
          <Table striped bordered hover>
            <thead style={{ textAlign: "center" }}>
              <tr>
                <th>Language</th>
                <th>Status</th>
                <th>Link</th>
              </tr>
            </thead>
            <tbody>
              {processingData.map((data, index) => (
                <tr key={data.TargetLangId}>
                  <td style={{ textAlign: "left", width: "33%" }}>
                    {languageList.find((lang) => lang.Id === data.TargetLangId)
                      ?.Name || data.TargetLangId}
                  </td>
                  <td style={{ textAlign: "left" }}>
                    {data.Status === 2 ? (
                      <span className="ready">Ready</span>
                    ) : data.Status === 3 ? (
                      <span>
                        <span>Error</span>
                      </span>
                    ) : (
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "12px",
                            fontWeight: 500,
                            color: "grey",
                          }}
                        >
                          {convertToLocaleTime(
                            new Date(data.SpectralTranslateETA)
                          ) ? (
                            Math.round(
                              convertToLocaleTime(
                                new Date(data.SpectralTranslateETA)
                              )
                            ) -
                              seconds >=
                            0 ? (
                              <span
                                style={{
                                  fontSize: "12px",
                                  fontWeight: 500,
                                  color: "grey",
                                }}
                              >
                                {convertToLocaleTime(
                                  new Date(data.SpectralTranslateETA)
                                )
                                  ? Math.round(
                                      convertToLocaleTime(
                                        new Date(data.SpectralTranslateETA)
                                      )
                                    ) -
                                      seconds >=
                                    0
                                    ? formatTime(
                                        Math.round(
                                          convertToLocaleTime(
                                            new Date(data.SpectralTranslateETA)
                                          )
                                        ) - seconds
                                      )
                                    : "Almost there..."
                                  : (index + 1) * 5 - seconds >= 0
                                  ? formatTime((index + 1) * 5 - seconds)
                                  : "Almost there"}
                              </span>
                            ) : (
                              "Almost there..."
                            )
                          ) : (index + 1) * 5 - seconds >= 0 ? (
                            formatTime((index + 1) * 5 - seconds)
                          ) : (
                            "Almost there..."
                          )}
                        </span>
                        <meter
                          style={{ width: "200px" }}
                          value={seconds}
                          max={
                            convertToLocaleTime(
                              new Date(data.SpectralTranslateETA)
                            )
                              ? Math.round(
                                  convertToLocaleTime(
                                    new Date(data.SpectralTranslateETA)
                                  )
                                )
                              : (index + 1) * 5
                          }
                        ></meter>
                      </span>
                    )}
                  </td>

                  {data.DubbedVideoUrl !== "" ? (
                    <td style={{ textAlign: "center", width: "33%" }}>
                      <button
                        className="viewDownloadButton"
                        onClick={() => {
                          viewProject(
                            data.DubbedVideoUrl,
                            languageList.find(
                              (lang) => lang.Id === data.TargetLangId
                            )?.Name || data.TargetLangId
                          );
                        }}
                      >
                        View
                      </button>
                      <button className="viewDownloadButton ms-2">
                        <a
                          style={{ textDecoration: "none", color: "grey" }}
                          href={data.DubbedVideoUrl}
                        >
                          Download
                        </a>
                      </button>
                    </td>
                  ) : (
                    <td style={{ textAlign: "center", width: "33%" }}></td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        ) : null}
      </div>
      {dubbedData.length === 0 ? (
        <div className="submitBtnContainer">
          <span>
            {flag === false && dubbedData.length === 0 ? (
              <button className="submitBtn" onClick={refresh}>
                <span
                  style={{
                    fontSize: "24px",
                    marginRight: "5px",
                    fontWeight: 500,
                  }}
                >
                  <IoReloadCircleOutline />
                </span>
                Refresh
              </button>
            ) : (
              <span>
                {dubbedData.length === 0 ? (
                  <button disabled className="submitBtnDisabled">
                    <span
                      style={{
                        fontSize: "24px",
                        marginRight: "5px",
                        fontWeight: 500,
                      }}
                    >
                      <IoReloadCircleOutline />
                    </span>
                    Refresh
                  </button>
                ) : null}
                {dubbedData.length === 0 ? (
                  <span style={{ marginLeft: "10px" }}>
                    <img
                      style={{ width: "50px", height: "50px" }}
                      src={loading}
                      alt="loading"
                    />
                  </span>
                ) : null}
              </span>
            )}
          </span>
        </div>
      ) : null}

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{viewLanguage}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
          <video
            src={viewUrl}
            style={{ height: "100%", width: "100%" }}
            controls
          ></video>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
