import React, { useState, useRef } from "react";
import { Row, Col, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "./spectralPersonalize.css";
import InputGroup from "react-bootstrap/InputGroup";
import { FaUpload, FaGoogleDrive, FaDropbox } from "react-icons/fa6";
import { ImOnedrive } from "react-icons/im";
import { GrClose } from "react-icons/gr";

export default function PersonalizePage1({ setSelectedPersonalizeComponent }) {
  const [file, setFile] = useState(null);
  const [url, setUrl] = useState("");
  const fileInputRef = useRef(null);
  const [selectedDataFile, setSelectedDataFile] = useState(null);

  const handleDataFileChange = (e) => {
    const dataFile = e.target.files[0];
    setSelectedDataFile(dataFile);
  };
  let languageList = [
    "Afrikaans",
    "Arabic",
    "Assamese",
    "Bengali",
    "Bhojpuri",
    "English Australia",
    "English India",
    "English UK",
    "English US",
    "French",
    "German",
    "Gujarati",
    "Hindi",
    "Icelandic",
    "Indonesian",
    "Italian",
    "Japanese",
    "Kannada",
    "Korean",
    "Malay",
    "Malayalam",
    "Mandarin",
    "Marathi",
    "Marwari",
    "Nepali",
    "Portuguese",
    "Punjabi",
    "Russian",
    "Spanish",
    "Swedish",
    "Tamil",
    "Telugu",
    "Ukrainian",
    "Urdu",
    "Welsh",
  ];

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    // console.log(selectedFile)
    setFile(selectedFile);
  };
  const handleDrop = (e) => {
    e.preventDefault();
    const selectedFile = e.dataTransfer.files[0];
    setFile(selectedFile);
  };

  const handleDelete = () => {
    setFile(null);
    setUrl("");
  };
  return (
    <div className="spectralPersonalize1">
      <div className="spectralPersonalizeCommon">
        <div className="personalize1">
          <Form.Group as={Row} className="mb-3" controlId="formPlaintext">
            <Form.Label column sm="2">
              Project Name :
            </Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                placeholder="Enter Project Name"
                style={{ width: "500px" }}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formPlaintext">
            <Form.Label column sm="2">
              Result as :
            </Form.Label>
            <Col sm="10">
              <Form.Check
                type="radio"
                label="Video Download"
                id={"videoDownload"}
                className="mt-2"
                checked
              />
            </Col>
          </Form.Group>
          <div>
            <div
              className="personalizeFileUpload mb-5"
              onDragOver={(e) => e.preventDefault()}
              onDrop={handleDrop}
            >
              {file !== null || url.trim() !== "" ? (
                <Button
                  variant="text"
                  className="delete-button"
                  style={{
                    position: "absolute",
                    top: 15,
                    right: 15,
                    fontWeight: 700,
                  }}
                  onClick={handleDelete}
                >
                  <GrClose />
                </Button>
              ) : null}
              {file === null && url.trim() === "" ? (
                <div>
                  <Button
                    variant="text"
                    style={{ fontSize: "50px", marginBottom: "10px" }}
                    onClick={handleUploadClick}
                  >
                    <FaUpload />
                  </Button>
                  <p style={{ color: "black" }}>
                    Drop your video here, or click to browse
                  </p>
                  <p style={{ color: "black" }}>
                    Paste video URL (We currently support links from{" "}
                    <FaGoogleDrive /> Google Drive, <FaDropbox /> Dropbox and{" "}
                    <ImOnedrive /> One Drive)
                  </p>
                  <InputGroup size="sm" className="mb-3 ">
                    <InputGroup.Text
                      id="inputGroup-sizing-sm"
                      style={{ color: "black", borderColor: "grey" }}
                    >
                      URL
                    </InputGroup.Text>
                    <Form.Control
                      style={{ borderColor: "grey" }}
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                      onBlur={(e) => setUrl(e.target.value)}
                    />
                  </InputGroup>
                  <input
                    ref={fileInputRef}
                    type="file"
                    style={{ display: "none" }}
                    accept=".mp4"
                    onChange={handleFileChange}
                  />
                </div>
              ) : (
                <div style={{ position: "absolute" }}>
                  {file !== null ? (
                    <video
                      src={URL.createObjectURL(file)}
                      style={{
                        width: "auto",
                        height: "40vh",
                        marginBottom: "10px",
                      }}
                      controls
                    />
                  ) : (
                    <video
                      src={url}
                      style={{
                        width: "auto",
                        height: "40vh",
                        marginBottom: "10px",
                      }}
                      controls
                    />
                  )}
                </div>
              )}
            </div>
          </div>
          <Form.Group as={Row} className="mb-3" controlId="formPlaintext">
            <Form.Label column sm="2">
              Language :
            </Form.Label>
            <Col sm="10">
              <Form.Select style={{ width: "300px" }}>
                <option>Select Language</option>
                {languageList.map((language) => (
                  <option>{language}</option>
                ))}
              </Form.Select>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formPlaintext">
            <Form.Label column sm="2">
              Data File :
            </Form.Label>
            <Col sm="10">
              <div>
                <Form.Control
                  type="file"
                  accept=".mp4"
                  onChange={handleDataFileChange}
                  style={{ width: "500px", marginBottom: "25px" }}
                />
                {selectedDataFile && (
                  <p>Selected file: {selectedDataFile.name}</p>
                )}
              </div>
            </Col>
          </Form.Group>
        </div>
      </div>
      <div
        className="mt-4"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <button
          className="nxtBtn"
          onClick={(e) => setSelectedPersonalizeComponent("personalize2")}
        >
          Continue
        </button>
      </div>
    </div>
  );
}
