import React, { useEffect, useState } from 'react';
import { Navbar, NavDropdown, Nav, Row, Button } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';
import userPic from '../multimedia/userpic.png';
import RecentProjects from './RecentProjects';
import SpectralTranscend from './DashboardComponents/SpectralTranscendComponents/SpectralTranscend';
import MyAssets from './DashboardComponents/MyAssets';
import SpectralPersonalize from './DashboardComponents/SpectralPersonalizeComponents/SpectralPersonalize';
import SprectralTranslate from './DashboardComponents/SpectralTranslate/SprectralTranslate';
import VoiceLibrary from './DashboardComponents/VoiceLibraryComponents/VoiceLibrary';
import VoiceLab from './DashboardComponents/VoiceLabComponents/VoiceLab';
import VoiceTransfer from './DashboardComponents/VoiceTransferComponents/VoiceTransfer';
import Profile from './DashboardComponents/Profile';
import { MdArrowBackIos } from 'react-icons/md';
import Admin from './DashboardComponents/Admin/Admin';
import DigitalTwin from './DashboardComponents/SpectralDRM/DigitalTwin';
import Projects from './DashboardComponents/SpectralDRM/Projects';
import DashboardDRM from './DashboardComponents/SpectralDRM/DashboardDRM';
import AuditLog from './DashboardComponents/SpectralDRM/AuditLog';

export default function UserPage({
  selectedDashboardComponent,
  setSelectedDashboardComponent,
  render,
  setRender,
  userId,
  setUserId,
  selectedTranscendComponent,
  setSelectedTranscendComponent,
  selectedVoiceTransferComponent,
  setSelectedVoiceTransferComponent,
  selectedTranslateComponent,
  setSelectedTranslateComponent,
  menuLock,
  setMenuLock,
  selectedAdminComponent,
  setSelectedAdminComponent,
  selectedDigitalTwinComponent,
  setSelectedDigitalTwinComponent,
  selectedProjectsComponent,
  setSelectedProjectsComponent,
  setShowProjectsSubButtons,
  showProjectsSubButtons,
}) {
  const navigate = useNavigate();
  const { component } = useParams();
  const [searchText, setSearchText] = useState('');
  const [helpUrl, setHelpUrl] = useState('');
  const [projectName, setProjectName] = useState('');
  // ----------------------

  localStorage.getItem('userSessionId');
  const [userInfo, setUserInfo] = useState(
    JSON.parse(localStorage.getItem('userInfo'))
  );

  useEffect(() => {
    // Update userInfo state whenever localStorage userInfo changes
    setUserInfo(JSON.parse(localStorage.getItem('userInfo')));
  }, [localStorage.getItem('userInfo')]);

  const renderSelectedDashboardComponent = () => {
    if (JSON.parse(localStorage.getItem('userInfo')).IsDubbingClient === true) {
      switch (selectedDashboardComponent) {
        case 'home':
          // return <RecentProjects searchText={searchText} setSearchText={setSearchText} />;
          navigate('/spectral-transcend');
          return (
            <SpectralTranscend
              key={render}
              searchText={searchText}
              setSearchText={setSearchText}
              menuLock={menuLock}
              setMenuLock={setMenuLock}
              setUserInfo={setUserInfo}
              selectedTranscendComponent={selectedTranscendComponent}
              setSelectedTranscendComponent={setSelectedTranscendComponent}
              projectName={projectName}
              setProjectName={setProjectName}
            />
          );
        case 'spectral-transcend':
          if (userInfo && userInfo.TranscendAccess === true) {
            return (
              <SpectralTranscend
                key={render}
                searchText={searchText}
                setSearchText={setSearchText}
                menuLock={menuLock}
                setMenuLock={setMenuLock}
                setUserInfo={setUserInfo}
                selectedTranscendComponent={selectedTranscendComponent}
                setSelectedTranscendComponent={setSelectedTranscendComponent}
                projectName={projectName}
                setProjectName={setProjectName}
              />
            );
          } else {
            return null;
          }
        case 'spectral-translate':
          navigate('/spectral-transcend');

        case 'voice-library':
          navigate('/spectral-transcend');

        case 'voice-transfer':
          if (userInfo && userInfo.VTAccess === true) {
            // Adding case for voice-transfer
            return (
              <VoiceTransfer
                key={render}
                searchText={searchText}
                setSearchText={setSearchText}
                menuLock={menuLock}
                setMenuLock={setMenuLock}
                setUserInfo={setUserInfo}
                selectedVoiceTransferComponent={selectedVoiceTransferComponent}
                setSelectedVoiceTransferComponent={
                  setSelectedVoiceTransferComponent
                }
                projectName={projectName}
                setProjectName={setProjectName}
              />
            );
          } else {
            return null;
          }
        case 'voice-transfer':
          if (userInfo && userInfo.VTAccess === true) {
            // Adding case for voice-transfer
            return (
              <VoiceTransfer
                key={render}
                searchText={searchText}
                setSearchText={setSearchText}
                menuLock={menuLock}
                setMenuLock={setMenuLock}
                setUserInfo={setUserInfo}
                selectedVoiceTransferComponent={selectedVoiceTransferComponent}
                setSelectedVoiceTransferComponent={
                  setSelectedVoiceTransferComponent
                }
                projectName={projectName}
                setProjectName={setProjectName}
              />
            );
          }

        case 'profile':
          return (
            <Profile
              key={render}
              userInfo={userInfo}
              setUserInfo={setUserInfo}
            />
          );
        case 'admin':
          if (userInfo && userInfo.IsAdmin === true) {
            return (
              <Admin
                key={render}
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                selectedAdminComponent={selectedAdminComponent}
                setSelectedAdminComponent={setSelectedAdminComponent}
                searchText={searchText}
                setSearchText={setSearchText}
              />
            );
          } else {
            return null;
          }
        case 'tutorials':
          return <RecentProjects searchText={searchText} />;
        case 'faq':
          return <RecentProjects searchText={searchText} />;
        case 'support':
          return <RecentProjects searchText={searchText} />;
        default:
          return null;
      }
    } else {
      switch (selectedDashboardComponent) {
        case 'home':
          // return <RecentProjects searchText={searchText} setSearchText={setSearchText} />;
          if (
            userInfo &&
            userInfo.TranscendAccess === true &&
            userInfo.Role !== 'DRM'
          ) {
            navigate('/spectral-transcend');

            return (
              <SpectralTranscend
                key={render}
                searchText={searchText}
                setSearchText={setSearchText}
                menuLock={menuLock}
                setMenuLock={setMenuLock}
                setUserInfo={setUserInfo}
                selectedTranscendComponent={selectedTranscendComponent}
                setSelectedTranscendComponent={setSelectedTranscendComponent}
                projectName={projectName}
                setProjectName={setProjectName}
              />
            );
          } else {
            navigate('/drm-dashboard');

            return (
              <DashboardDRM
                key={render}
                searchText={searchText}
                setSearchText={setSearchText}
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                selectedDashboardComponent={selectedDashboardComponent}
                setSelectedDashboardComponent={setSelectedDashboardComponent}
              />
            );
          }
        case 'my-assets':
          return <MyAssets />;
        case 'spectral-transcend':
          if (userInfo && userInfo.TranscendAccess === true) {
            return (
              <SpectralTranscend
                key={render}
                searchText={searchText}
                setSearchText={setSearchText}
                menuLock={menuLock}
                setMenuLock={setMenuLock}
                setUserInfo={setUserInfo}
                selectedTranscendComponent={selectedTranscendComponent}
                setSelectedTranscendComponent={setSelectedTranscendComponent}
                projectName={projectName}
                setProjectName={setProjectName}
              />
            );
          } else {
            return null;
          }
        case 'spectral-personalize':
          return (
            <SpectralPersonalize
              key={render}
              searchText={searchText}
              setSearchText={setSearchText}
              setUserInfo={setUserInfo}
            />
          );
        case 'spectral-translate':
          if (userInfo && userInfo.TranslateAccess === true) {
            return (
              <SprectralTranslate
                key={render}
                searchText={searchText}
                setSearchText={setSearchText}
                setUserInfo={setUserInfo}
                menuLock={menuLock}
                setMenuLock={setMenuLock}
                selectedTranslateComponent={selectedTranslateComponent}
                setSelectedTranslateComponent={setSelectedTranslateComponent}
                projectName={projectName}
                setProjectName={setProjectName}
              />
            );
          } else {
            return null;
          }
        case 'voice-library':
          if (userInfo && userInfo.VoiceLibAccess === true) {
            return (
              <VoiceLibrary
                key={render}
                searchText={searchText}
                setSearchText={setSearchText}
                userInfo={userInfo}
                setUserInfo={setUserInfo}
              />
            );
          } else {
            return null;
          }
        case 'voice-lab':
          if (userInfo && userInfo.VoiceLabAccess === true) {
            return (
              <VoiceLab
                key={render}
                searchText={searchText}
                setSearchText={setSearchText}
                userInfo={userInfo}
                setUserInfo={setUserInfo}
              />
            );
          } else {
            return null;
          }

        case 'drm-dashboard':
          return (
            <DashboardDRM
              key={render}
              searchText={searchText}
              setSearchText={setSearchText}
              userInfo={userInfo}
              setUserInfo={setUserInfo}
              selectedDashboardComponent={selectedDashboardComponent}
              setSelectedDashboardComponent={setSelectedDashboardComponent}
              showProjectsSubButtons={showProjectsSubButtons}
              setShowProjectsSubButtons={setShowProjectsSubButtons}
            />
          );

        case 'voice-transfer':
          if (userInfo && userInfo.VTAccess === true) {
            // Adding case for voice-transfer
            return (
              <VoiceTransfer
                key={render}
                searchText={searchText}
                setSearchText={setSearchText}
                menuLock={menuLock}
                setMenuLock={setMenuLock}
                setUserInfo={setUserInfo}
                selectedVoiceTransferComponent={selectedVoiceTransferComponent}
                setSelectedVoiceTransferComponent={
                  setSelectedVoiceTransferComponent
                }
                projectName={projectName}
                setProjectName={setProjectName}
              />
            );
          } else {
            return null;
          }

        case 'profile':
          return (
            <Profile
              key={render}
              userInfo={userInfo}
              setUserInfo={setUserInfo}
            />
          );

        case 'digital-twin':
          return (
            <DigitalTwin
              key={render}
              // searchText={searchText}
              // setSearchText={setSearchText}
              // userInfo={userInfo}
              // setUserInfo={setUserInfo}
              searchText={searchText}
              setSearchText={setSearchText}
              userInfo={userInfo}
              setUserInfo={setUserInfo}
              selectedDigitalTwinComponent={selectedDigitalTwinComponent}
              setSelectedDigitalTwinComponent={setSelectedDigitalTwinComponent}
            />
          );
        case 'projects':
          return (
            <Projects
              key={render}
              // searchText={searchText}
              // setSearchText={setSearchText}
              // userInfo={userInfo}
              // setUserInfo={setUserInfo}
              selectedProjectsComponent={selectedProjectsComponent}
              setSelectedProjectsComponent={setSelectedProjectsComponent}
            />
          );
        case 'audit-log':
          return (
            <AuditLog
              key={render}
              searchText={searchText}
              setSearchText={setSearchText}
              userInfo={userInfo}
              setUserInfo={setUserInfo}
            />
          );
        case 'admin':
          if (userInfo && userInfo.IsAdmin === true) {
            return (
              <Admin
                key={render}
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                selectedAdminComponent={selectedAdminComponent}
                setSelectedAdminComponent={setSelectedAdminComponent}
                searchText={searchText}
                setSearchText={setSearchText}
              />
            );
          } else {
            return null;
          }
        case 'tutorials':
          return <RecentProjects searchText={searchText} />;
        case 'faq':
          return <RecentProjects searchText={searchText} />;
        case 'support':
          return <RecentProjects searchText={searchText} />;
        default:
          return null;
      }
    }
  };

  useEffect(() => {
    if (component) {
      setSelectedDashboardComponent(component);
      setSearchText('');
      if (component === 'spectral-transcend') {
        setHelpUrl('https://go.cynapto.com/iRNjB');
      } else if (component === 'spectral-translate') {
        setHelpUrl('https://go.cynapto.com/U6n7u');
      } else if (component === 'voice-library') {
        setHelpUrl('https://go.cynapto.com/UkyGe');
      }
    }
  }, [component, selectedDashboardComponent]);

  function redirect() {
    // console.log('clicked redirect');
    // console.log('component is',selectedDashboardComponent);
    setProjectName('');
    if (selectedDashboardComponent === 'spectral-transcend') {
      setMenuLock(false);
      setSelectedTranscendComponent('transcendHome');
      navigate('/spectral-transcend');
      setRender(!render);
    } else if (selectedDashboardComponent === 'voice-transfer') {
      setMenuLock(false);
      setSelectedVoiceTransferComponent('voiceTransferHome');
      navigate('/voice-transfer');
      setRender(!render);
    } else if (selectedDashboardComponent === 'spectral-translate') {
      setMenuLock(false);
      setSelectedTranslateComponent('tHome');
      navigate('/spectral-translate');
      setRender(!render);
    }
  }

  const handleLogout = () => {
    // clear user session, redirect to login page
    if (localStorage.getItem('userSessionId') !== null) {
      setUserId('');
      localStorage.clear();
      navigate('/account/login');
    }
  };
  return (
    <div className="user-page">
      <Row>
        <Navbar className="user-nav ">
          <Nav
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '25px',
            }}
          >
            {menuLock === false ? (
              <div className="search-bar">
                <div className="search-icon">
                  <BsSearch />
                </div>
                <input
                  value={searchText}
                  className="search"
                  type="search"
                  placeholder="Search"
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                />
              </div>
            ) : (
              <div className="back-div">
                <Button
                  variant="text"
                  style={{ fontSize: '20px', fontWeight: 600 }}
                  onClick={(e) => redirect()}
                >
                  <MdArrowBackIos />
                </Button>
                {projectName !== null &&
                projectName !== '' &&
                projectName !== undefined ? (
                  <span className="projectName">{projectName}</span>
                ) : null}
              </div>
            )}

            <span
              className="ms-auto"
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {localStorage.getItem('userSessionId') !== null &&
              userInfo &&
              userInfo.Role !== 'DRM' ? (
                <span
                  className="me-3"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center',
                  }}
                >
                  <label htmlFor="credits">
                    {userInfo.UsageCredits}/{userInfo.LimitCredits} min
                  </label>
                  <meter
                    className="credit-range"
                    id="credits"
                    min="0"
                    max={userInfo.LimitCredits}
                    value={userInfo.UsageCredits}
                  >
                    {userInfo.UsageCredits}/{userInfo.LimitCredits}
                  </meter>
                </span>
              ) : null}
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {userInfo.Role !== 'DRM' ? (
                  <button
                    className="help-btn"
                    onClick={() => {
                      window.open(helpUrl, '_blank');
                    }}
                  >
                    <AiOutlineQuestionCircle />
                  </button>
                ) : null}

                <NavDropdown
                  className="user"
                  title={
                    <img
                      src={userPic}
                      alt="userpic"
                      style={{ width: '60px', height: '40px' }}
                    />
                  }
                  id="basic-nav-dropdown"
                >
                  {localStorage.getItem('userSessionId') !== null ? (
                    <NavDropdown.Item onClick={(e) => navigate('/profile')}>
                      Profile
                    </NavDropdown.Item>
                  ) : null}
                  {localStorage.getItem('userSessionId') !== null ? (
                    <NavDropdown.Item onClick={handleLogout}>
                      Logout
                    </NavDropdown.Item>
                  ) : null}
                </NavDropdown>
              </span>
            </span>
          </Nav>
        </Navbar>
      </Row>
      <Row>
        <div style={{ height: '90vh', borderTop: '1px solid lightgrey' }}>
          {renderSelectedDashboardComponent()}
        </div>
      </Row>
    </div>
  );
}
