import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { HiScissors } from "react-icons/hi2";
import { FiMoreVertical } from "react-icons/fi";
import { IoChevronForwardOutline } from "react-icons/io5";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import { useNavigate } from "react-router-dom";

export default function MyAssets() {
  const [videos, setVideos] = useState([]);

  const apiKey = "fTj2NO2kZFTkNnrk9FLHz8dpMidvGbtBy3feCBOuorkVrGyRcX3o2AOd";
  const apiURL = `https://api.pexels.com/videos/search?query=mountains&per_page=3&page=1`;
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "My Assets - Spectral Studio";
  }, []);

  const fetchVideos = async () => {
    try {
      const response = await fetch(apiURL, {
        headers: {
          Authorization: apiKey,
        },
      });
      const data = await response.json();
      setVideos(data.videos);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchVideos();
  }, []);

  const handleThumbnailClick = (videoUrl) => {
    const iframe = document.createElement("iframe");
    iframe.src = videoUrl;
    iframe.width = 900;
    iframe.height = 600;
    const newTab = window.open();
    newTab.document.body.appendChild(iframe);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedTime = `${minutes}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
    return formattedTime;
  };
  return (
    <div>
      <Row
        className="my-assets"
        style={{ overflowY: "auto", maxHeight: "78vh", marginBotton: "50px" }}
      >
        <Col md={12}>
          <h2 className="intro-text">MyAssets</h2>
          <div
            style={{
              marginLeft: "50px",
              marginTop: "50px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <span style={{ fontWeight: 500, fontSize: "18px", color: "grey" }}>
              My Videos
            </span>
            <button className="allvid-btn">
              All Videos
              <span style={{ marginLeft: "6px" }}>
                <IoChevronForwardOutline />
              </span>
            </button>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {videos.map((video, index) => (
              <Card
                className="recentProjectsCard mt-4 ms-5"
                key={video.id}
                style={{
                  border: "none",
                  backgroundColor: "#f7f7f9",
                }}
              >
                <div
                  style={{
                    position: "relative", // Add this line
                    flex: "1", // Expand to fill available space
                  }}
                >
                  <Card.Img
                    className="recentProjectsThumbnail"
                    src={video.image}
                    alt={video.url}
                    onClick={() =>
                      handleThumbnailClick(video.video_files[0].link)
                    }
                  />
                </div>
                <Card.Text
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    color: "black",
                    fontWeight: 500,
                    marginTop: "8px",
                  }}
                >
                  <p style={{ marginLeft: "20px" }}>
                    Video Name - {index + 1}
                    <br />
                    <span style={{ color: "grey", fontWeight: 400 }}>
                      Last Modified: 23 hours ago
                    </span>
                  </p>
                  <button
                    className="options-button"
                    style={{
                      border: "none",
                      backgroundColor: "#f7f7f9",
                      fontSize: "25px",
                      outline: "none",
                    }}
                  >
                    <FiMoreVertical />
                  </button>{" "}
                </Card.Text>
              </Card>
            ))}
          </div>

          {/* Audio files here */}

          <div
            style={{
              marginLeft: "50px",
              marginTop: "50px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <span style={{ fontWeight: 500, fontSize: "18px", color: "grey" }}>
              My Audios
            </span>
            <button className="allvid-btn">
              All Audios
              <span style={{ marginLeft: "6px" }}>
                <IoChevronForwardOutline />
              </span>
            </button>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {videos.map((video, index) => (
              <Card
                className="recentProjectsCard mt-4 ms-5"
                key={video.id}
                style={{
                  border: "none",
                  backgroundColor: "#f7f7f9",
                }}
              >
                <div
                  style={{
                    position: "relative", // Add this line
                    flex: "1", // Expand to fill available space
                  }}
                >
                  <Card.Img
                    className="recentProjectsThumbnail"
                    src={video.image}
                    alt={video.url}
                    onClick={() =>
                      handleThumbnailClick(video.video_files[0].link)
                    }
                  />
                </div>
                <Card.Text
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    color: "black",
                    fontWeight: 500,
                    marginTop: "8px",
                  }}
                >
                  <p style={{ marginLeft: "20px" }}>
                    Audio Name - {index + 1}
                    <br />
                    <span style={{ color: "grey", fontWeight: 400 }}>
                      Last Modified: 23 hours ago
                    </span>
                  </p>
                  <button
                    className="options-button"
                    style={{
                      border: "none",
                      backgroundColor: "#f7f7f9",
                      fontSize: "25px",
                      outline: "none",
                    }}
                  >
                    <FiMoreVertical />
                  </button>{" "}
                </Card.Text>
              </Card>
            ))}
          </div>
        </Col>
      </Row>
    </div>
  );
}
