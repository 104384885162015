import React from "react";
import confirm from "../../../multimedia/greentick1.png";

export default function FileUploadMessage() {
  return (
    <div className="syncPage">
      <div className="syncPage2 confirm-tick" style={{ height: "100%" }}>
        <img
          src={confirm}
          style={{ width: "25%", height: "auto" }}
          alt="confirmation"
        />
        <p style={{ fontWeight: 600 }}>Project created successfully</p>
      </div>
    </div>
  );
}
