import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { IoArrowBack } from 'react-icons/io5';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import AudioWaveform from './AudioWaveform';
import './ExportTab.css';
import sample from '../../../multimedia/sample.mp3';
import { TiTick } from 'react-icons/ti';
import ReactGA from 'react-ga';
import gears from '../../../multimedia/loadingBigStarter.gif';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//enum values
const formats = { 1: 'wav', 2: 'mp3' };
const channels = { 1: 'Mono', 2: 'Stereo' };
const sampleRates = { 1: '16000 Hz', 2: '44100 Hz', 3: '48000 Hz' };
const qualities = {
  1: 'Low (128kbps)',
  2: 'Standard (192kbps)',
  3: 'High (320kbps)',
};
const encodings = {
  1: 'Signed 16 bit PCM',
  2: 'Signed 24 bit PCM',
  3: 'Signed 32 bit PCM',
};

export default function DubbedAudio({
  projectId,
  setSelectedVoiceTransferComponent,
  languageId,
  setLanguageId,
  setVoicesArray,
  setProjectId,
}) {
  const [data, setData] = useState('');
  const [mergeURL, setMergeURL] = useState('');
  const [flag, setFlag] = useState(false);
  const [flag2, setFlag2] = useState(false);

  const apiURL = process.env.REACT_APP_API_URL;
  const [showModal, setShowModal] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState('');
  const [show, setShow] = useState(false);
  const [showExport, setShowExport] = useState(false);

  //export tab

  const [peaks, setPeaks] = useState([]);
  const [waveDuration, setWaveDuration] = useState(null);

  const [format, setFormat] = useState(1);
  const [channel, setChannel] = useState(1);
  const [sampleRate, setSampleRate] = useState(2);
  const [encoding, setEncoding] = useState(1);
  const [quality, setQuality] = useState(2);

  const [wavesurfer, setWavesurfer] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const onReady = (ws) => {
    setWavesurfer(ws);
    setIsPlaying(false);
  };

  const onPlayPause = () => {
    wavesurfer && wavesurfer.playPause();
  };

  const handleExport = async () => {
    console.log('Exporting audio with the following settings:');
    console.log({
      format: format,
      channel: channel,
      sampleRate: sampleRate,
      encoding: encoding,
      quality: quality,
    });

    const response = await fetch(`${apiURL}/dub/export_merged_audio`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ProjectId: projectId,
        AudioUrl: data,
        Format: format,
        Channel: channel,
        SampleRate: sampleRate,
        Encoding: encoding,
        Quality: quality,
      }),
    });

    const newData = await response.json();
    if (newData.IsValid) {
      const link = document.createElement('a');
      link.href = newData.Url;

      link.setAttribute('download', 'yourfile.mp3');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } else {
      console.error('Error:', newData.Error);
      alert(`Error: ${newData.Error}`);
    }
  };

  //

  function getPeaksforWaveform() {
    const req = {
      ProjectId: projectId,
    };
    fetch(`${apiURL}/dub/get_audio_waveform`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Failed to fetch project data');
        }
      })
      .then((newData) => {
        if (newData.IsValid) {
          setPeaks(newData.Peaks);
          setWaveDuration(newData.Duration);
          setFlag2(true);
        }
      })
      .catch((error) => {
        // console.error('Error:', error);
      });
  }

  const handleClose = () => setShow(false);
  const handleCloseExport = () => setShowExport(false);

  const handleShow = () => {
    setShow(true);

    ReactGA.event({
      category: 'Modal',
      action: 'Open',
      label: 'dubbed_results/lip_sync',
    });
  };
  const handleShowExport = () => {
    setShowExport(true);

    ReactGA.event({
      category: 'Modal',
      action: 'Open',
      label: 'dubbed_results/lip_sync',
    });
  };

  const openModal = (errorMessage) => {
    setModalErrorMessage(errorMessage);
    setShowModal(true);
  };

  const closeModal = () => setShowModal(false);

  const notify = (error) =>
    toast.error(error, {
      className: 'custom-error-toast',
    });

  // google analytics

  const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview('/spectral-transcend/dubbed_results');
    ReactGA.set({ page: '/spectral-transcend/dubbed_results' });
  }, []);

  const fetchVoice = async (project_Id) => {
    const reqBody = {
      UserId: localStorage.getItem('userSessionId'),
      ProjectId: project_Id,
    };
    try {
      const response = await fetch(`${apiURL}/web/get_voices`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.IsValid) {
          localStorage.setItem('voiceList', JSON.stringify(data.VoiceList));
          setVoicesArray(data.VoiceList);
        }
      } else {
        throw new Error('Failed to fetch project data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  async function change_project_result_status(currentStatus, data) {
    const req = {
      ProjectId: projectId,
      CurrentStatus: currentStatus,
      TargetStatus: 204,
    };
    try {
      const response = await fetch(
        `${apiURL}/web/change_project_result_status`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // 'ApiKey': apiKey,
          },
          body: JSON.stringify(req),
        }
      );
      const res = await response.json();

      if (res.IsValid) {
        setProjectId(data.Id);
        setLanguageId(data.ResultVideos[0].TargetLangId);
        fetchVoice(data.Id);
        setSelectedVoiceTransferComponent('audio_segments');
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getProject(id) {
    const req = {
      ProjectId: id,
    };
    try {
      const response = await fetch(`${apiURL}/web/get_project`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // 'ApiKey': apiKey,
        },
        body: JSON.stringify(req),
      });
      const data = await response.json();

      if (data.IsValid) {
        change_project_result_status(data.ResultVideos[0].Status, data);
      } else {
        notify('Redirection to previous stage was unsuccessful');
      }
    } catch (error) {
      console.error(error);
      notify('Redirection to previous stage was unsuccessful');
    }
  }

  function back() {
    getProject(projectId);
  }

  useEffect(() => {
    const fetchVideo = async () => {
      const req = {
        ProjectId: projectId,
        // TargetLangId: languageId,
      };
      try {
        const response = await fetch(`${apiURL}/dub/check_VT_merging_status`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(req),
        });

        if (response.ok) {
          const newData = await response.json();
          if (newData.IsValid) {
            if (newData.MergingStatus === 2) {
              setData(newData.Merged_VT_AudioUrl);
              setMergeURL(newData.Merged_VT_AudioUrl);
              clearInterval(intervalId);
              getPeaksforWaveform();

              setFlag(true);
            }

            // newL(data);
          }
        } else {
          throw new Error('Failed to fetch project data');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchVideo();
    const intervalId = setInterval(fetchVideo, 5000);
    return () => clearInterval(intervalId);
  }, []);

  async function handleSync() {
    const req = {
      ProjectId: projectId,
      TargetLangId: languageId,
    };
    try {
      const response = await fetch(`${apiURL}/dub/spectral_sync`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(req),
      });

      if (response.ok) {
        const res = await response.json();

        if (res.IsValid) {
          handleClose();
          setSelectedVoiceTransferComponent('spectral-sync');
        } else {
          if (res.Error === true) {
            handleClose();
            openModal(res.ErrorResponse);
          }
        }
      } else {
        throw new Error('Failed to speactral sync');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  return (
    // <div className='syncPage' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '70vh' }}>
    <div style={{ padding: '1%' }}>
      <Row>
        <Col md={12}>
          <div className="dubbedAudioPage" style={{ position: 'relative' }}>
            {/* <div className='pg4 result-page' > */}
            {flag === true ? (
              <div style={{ position: 'absolute', left: 10, top: 10 }}>
                <Button
                  style={{ fontSize: '20px', fontWeight: 500 }}
                  variant="text"
                  onClick={(e) => back()}
                >
                  <IoArrowBack />
                  <span className="ms-1" style={{ fontSize: '14px' }}>
                    Back
                  </span>
                </Button>
              </div>
            ) : null}
            <h5 className=" mb-4 text-center">Download Merged Audio</h5>
            <div className="result-audio-container">
              {data !== '' && flag2 == true ? (
                <div>
                  <div className="waveForm">
                    <AudioWaveform
                      peaks={peaks}
                      waveDuration={waveDuration}
                      sample={sample}
                      data={data}
                      setData={setData}
                    />
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '40vw',
                      marginTop: '40px',
                    }}
                  >
                    <img
                      style={{ height: '35%', width: '40%' }}
                      src={gears}
                      alt="In progress"
                    />

                    <p style={{ fontWeight: 500, marginTop: '20px' }}>
                      Please wait, localization is in progress.
                    </p>
                  </div>
                </div>
              )}
            </div>
            {data !== '' && flag2 == true ? (
              <div className="App22">
                <div className="exFormContainer">
                  <div className="subex">
                    <div className="form-group">
                      <div className="form-subgroup">
                        <label className="exLable">Format:</label>
                        <select
                          value={format}
                          onChange={(e) => setFormat(Number(e.target.value))}
                        >
                          {Object.entries(formats).map(([key, value]) => (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="form-subgroup">
                        <label className="exLable">Channels:</label>
                        {Object.entries(channels).map(([key, value]) => (
                          <label key={key}>
                            <input
                              type="radio"
                              name="channel"
                              value={key}
                              checked={channel === Number(key)}
                              onChange={() => setChannel(Number(key))}
                            />{' '}
                            {value}
                          </label>
                        ))}
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="form-subgroup">
                        <label className="exLable">Sample Rate:</label>
                        <select
                          value={sampleRate}
                          onChange={(e) =>
                            setSampleRate(Number(e.target.value))
                          }
                        >
                          {Object.entries(sampleRates).map(([key, value]) => (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          ))}
                        </select>
                      </div>
                      {format !== 2 ? ( // Hide the encoding option if format is mp3
                        <div className="form-subgroup">
                          <label className="exLable">Encoding:</label>
                          <select
                            value={encoding}
                            onChange={(e) =>
                              setEncoding(Number(e.target.value))
                            }
                          >
                            {Object.entries(encodings).map(([key, value]) => (
                              <option key={key} value={key}>
                                {value}
                              </option>
                            ))}
                          </select>
                        </div>
                      ) : (
                        <div className="form-subgroup">
                          <label className="exLable">Quality:</label>
                          <select
                            value={quality}
                            onChange={(e) => setQuality(Number(e.target.value))}
                          >
                            {Object.entries(qualities).map(([key, value]) => (
                              <option key={key} value={key}>
                                {value}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </Col>
      </Row>
      <div className="nxtBtnContainer">
        {flag === true && data !== '' && flag2 == true ? (
          <div>
            {' '}
            {/* <a href={data}>
              <button className="nxtBtn ">Export</button>
            </a>{' '} */}
            <button className="nxtBtn ms-2" onClick={(e) => handleExport()}>
              Export
            </button>{' '}
          </div>
        ) : (
          <button className="nxtBtn" disabled>
            Export
          </button>
        )}
      </div>

      <Modal show={showModal} onHide={closeModal} size="md">
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: 'red' }}>{modalErrorMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <h5>Before start, make sure your video meets these criteria:</h5>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>
              <span className="me-1">
                <TiTick />
              </span>
              <span>The face is clearly visible</span>
            </p>
            <p>
              <span className="me-1">
                <TiTick />
              </span>
              <span>Nothing covers the eyes, nose, mouth and eyebrows</span>
            </p>
            <p>
              <span className="me-1">
                <TiTick />
              </span>
              <span>The light is good, no shadows or over exposure</span>
            </p>
          </div>
          <div style={{ width: '100%' }}>
            <Button
              style={{ width: '100%' }}
              className="mt-3"
              variant="primary"
              onClick={(e) => handleSync()}
            >
              Start Lip-sync
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </div>
  );
}
